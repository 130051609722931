import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
} from "@coreui/react";
// sidebar nav config
import navigation from "./_nav";
import classes from "./nav.module.scss";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import Logo from "./logo.svg";
import SmallLogo from "./logo_1.svg";
import { useOnClickOutside } from "utils/hooks";
import { useRef } from "react";

//const Logo = process.env.PUBLIC_URL + "/logo.png";

// const Logo = "https://keepsafe.work/images/Keepsafe-logo.svg";

const Sidebar = ({ history }) => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow?.sidebarShow);
  const minimized = useSelector((state) => state.sidebarShow?.minimized);

  const toggleSidebar = (val) => {
    dispatch({ type: "set", sidebarShow: val, minimized: minimized });
  };

  const toggleMinimize = (val) => {
    dispatch({ type: "set", sidebarShow: show, minimized: val });
  };

  const routeKey = window?.location?.pathname.split("-")[1];

  const sidebarRef = useRef();

  const organisation = useSelector((state) => state?.auth?.organisation);

  console.log('organisation',organisation);

  useOnClickOutside(sidebarRef, () => toggleSidebar(false));
  return (
    <CSidebar
      id="sidebar"
      minimize={!minimized}
      ref={sidebarRef}
      style={{
        backgroundColor: "#2f353a",
        justifyContent: "flex-start",
        zIndex: "99999999",
      }}
      size="md"
      show={show}
      onShowChange={(val) => {
        toggleSidebar(val);
      }}
      onMinimizeChange={(val) => {
        toggleMinimize(val);
      }}
    >
      <style>{`@media print {.sidebar-supervisor{display: none;}}`}</style>
      <div className={cn(classes.brandWrapper, minimized && classes.center)}>
        <CSidebarBrand
          className="d-md-down-none"
          to="/"
          style={{
            background: "#262A32",
            height: "50px",
          }}
        >
          {organisation?.data['supervisor_logo'] && (
            <img
              style={{ paddingLeft: minimized && "12px" }}
              src={organisation?.data['supervisor_logo']}
              alt={"Logo"}
           />
          )}
          {/*
          <img
            alt={"Logo"}
            style={{ paddingLeft: minimized && "12px" }}
            src={!minimized ? SmallLogo : Logo}
          />
          */}
        </CSidebarBrand>
      </div>
      <CSidebarNav>
        <div className={classes.sidebar}>
          {navigation?.map((item) => (
            <div
              className={cn(
                classes.navigationItem,
                routeKey === item.key && classes.active,
                !minimized && classes.center
              )}
              onClick={() => {
                history.push(item.to);
              }}
            >
              <div className={classes.Icon}>{item.icon}</div>
              {minimized && <span>{item.name}</span>}
            </div>
          ))}
        </div>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default withRouter(Sidebar);
