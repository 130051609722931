// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  ORGANISATION_REQUEST,
  ORGANISATION_REQUEST_SUCCESS,
  ORGANISATION_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* getOrganisation() {
  try {
    const response = yield call(axios.get, "/organisation?subdomain=" + window?.location?.host);

    if (response.data.error) {
      yield put({
        type: ORGANISATION_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: ORGANISATION_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ORGANISATION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getOrganisationSaga() {
  yield takeLatest(ORGANISATION_REQUEST, getOrganisation);
}
