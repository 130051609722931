import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
//action start
import {
  supervisorEmailPasswordLoginRequest,
  resetError,
  removeToken,
  organisationRequest
} from "store/auth/actions";
//action end

import classes from "./auth.module.scss";

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectOrganisation
} from "store/auth/selector";
//selectors end
import { Formik } from "formik";
import ReactLoading from "react-loading";
import Input from "components/UI/input";
import MainLogo from "./logo.png";
import queryString from "query-string";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const Login = ({ onSubmitForm, error, resetErrorReq, loading, organisation, getOrganisation }) => {
  useEffect(() => {
    resetErrorReq();
    window.localStorage.removeItem("AUTH_TOKEN");
    window.localStorage.removeItem("access_token");
    removeToken();
    getOrganisation();
  }, []);

  useEffect(() => {
    
    console.log('organisation',organisation);

  }, [organisation]);

  const site = queryString?.parse(window.location.search)?.site;

  const initialValues = {
    email: "",
    password: "",
    site: site,
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.box}>
          {organisation?.data['logo'] && (
            <img
              className={classes.logo}
              //src={MainLogo}
              src={organisation?.data['logo']}
              alt={"Supervisor Main Logo"}
           />
          )}
          
          <>
            <div className={classes.wrapper}>
              <span className={classes.title}>Supervisor Login</span>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  onSubmitForm({ values });
                }}
              >
                {({ values, handleSubmit, setFieldValue, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      order={"column"}
                      error={errors.email}
                      label={"Email"}
                      placeholder={"Please enter your email"}
                    />
                    <Input
                      value={values.password}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                      error={errors.password}
                      type={"password"}
                      order={"column"}
                      label={"Password"}
                      placeholder={"Please enter your password"}
                    />
                    {error && typeof error === "string" && (
                      <span style={{ marginLeft: "5px", color: "red" }}>
                        {error}
                      </span>
                    )}
                    <button className={classes.button}>
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flex: "1",
                          }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#fff"}
                            height={25}
                            width={25}
                          />
                        </div>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  organisation: makeSelectOrganisation(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(supervisorEmailPasswordLoginRequest(val)),
    resetErrorReq: (val) => dispatch(resetError(val)),
    removeToken: (val) => dispatch(removeToken(val)),
    getOrganisation: (val) => dispatch(organisationRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
