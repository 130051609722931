import React, { useEffect, useState } from "react";
import classes from "./complete.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectProgress } from "store/progress/selector";
import { makeSelectSelectedInduction } from "store/induction/selector";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Heading from "components/UI/heading";
import Button from "components/UI/button";
import Footer from "components/UI/footer";

import { useLocate, saveProgress } from "utils/hooks";

import Tick from "assets/icons/assessmentTick.svg";
import Lottie from "react-lottie";

//animations
import CheckAnimation from "assets/animations/Check-small-v3.json";

const Complete = (props) => {
  var { current, next } = useLocate(
    props.selectedInduction,
    props?.history?.location.pathname
  );

  if(props?.isPreview)
     current = props?.pageData;

  const [tickVisible, setTickVisible] = useState(false);

  useEffect(() => {
    if(!props?.isPreview)
       saveProgress(props.selectedInduction, props.history.location.pathname);

    setTimeout(() => {
      setTickVisible(true);
    }, 600);
  }, []);

  return (
    <>
      <div className={classes.completeContainer}>
        <Heading
          title={props?.isPreview ? current?.title : `${props?.selectedInduction?.name} Complete`}
          titleId={
            props?.isPreview ? current?.title :
            current?.title_id
              ? current?.title_id
              : `${props?.selectedInduction?.name} Complete`
          }
          description={current?.data?.subtitle}
          descriptionId={
            props?.isPreview ? current?.data?.subtitle :
            current?.data?.subtitle_id ? current?.data?.subtitle_id : "default"
          }
        />

        <div
          style={{
            marginTop: "4vh",
            marginBottom: "4vh",
          }}
        >
          <div className={classes.CheckboxWrapper}>
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animationData: CheckAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                  progressiveLoad: true,
                },
              }}
              width={"60%"}
              isClickToPauseDisabled={true}
              isPaused={!tickVisible}
              title={"animation"}
              ariaLabel={"animation"}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0px 30px 0px 30px",
          }}
        >
          <span className={classes.Content}>
            <FormattedMessage
              defaultMessage={current?.data?.content}
              id={ props?.isPreview ? current?.data?.content :
                current?.data?.content_id
                  ? current?.data?.content_id
                  : current?.data?.content
              }
            />
          </span>
        </div>
      </div>
      {!props?.isPreview && (
        <Footer>
          {next?.url ? (
            <Link className={classes.Link} to={next?.url}>
              <Button
                id="apply-button"
                text="Continue"
                type="submit"
                theme="blue"
                formatID="continue.button"
              />
           </Link>
          ): (
          <Link className={classes.Link} to={"/site-selector"}>
            <Button
              id="apply-button"
              text="Continue"
              type="submit"
              theme="blue"
              formatID="continue.button"
            />
          </Link>
          )}
          
        </Footer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
