// @flow
import { put, call, takeLatest, delay } from "redux-saga/effects";
import {
  SUPERVISOR_NORMAL_LOGIN_REQUEST_ERROR,
  SUPERVISOR_NORMAL_LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_NORMAL_LOGIN_REQUEST,
} from "../constants";
import { push } from "connected-react-router";
import axios from "services/api";
import { isNil } from "lodash";

function* supervisorloginRequest(data) {
  try {
    const params = new URLSearchParams();

    params.append("email", data.values.email);
    params.append("password", data.values.password);
    //send organisation
    params.append("subdomain", window?.location?.host);

    //if we send old tokens we get 403 error
    delete axios.defaults.headers.common["X-Token"];
    delete axios.defaults.headers.common["X-Access-Token"];

    const response = yield call(axios.post, "/supervisor-login/", params);

    if (response.data.error) {
      yield put({
        type: SUPERVISOR_NORMAL_LOGIN_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      const token = response.data.token;

      axios.defaults.headers.common["X-Token"] = `${token}`;

      yield call([localStorage, localStorage.setItem], "access_token", token);
      yield delay(2000);

      yield put({
        type: SUPERVISOR_NORMAL_LOGIN_REQUEST_SUCCESS,
        data: response.data,
      });
      if (!isNil(data?.values?.site)) {
        yield put(push(`/check-in?site=${data?.values?.site}`));
      } else {
        yield put(push("/supervisor-dashboard"));
      }
    }
  } catch (error) {
    yield put({
      type: SUPERVISOR_NORMAL_LOGIN_REQUEST_ERROR,
      data: error.message,
    });
  }
}

export default function* supervisorloginRequestSaga() {
  yield takeLatest(SUPERVISOR_NORMAL_LOGIN_REQUEST, supervisorloginRequest);
}
