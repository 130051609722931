import React, { useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { makeSelectSelectedInduction } from "store/induction/selector";
import { makeSelectProgress } from "store/progress/selector";
//styling
import classes from "./introductory.module.scss";

//components
import Heading from "components/UI/heading";
import Button from "components/UI/button";
import Footer from "components/UI/footer";

//assets
import SoundIcon from "./sound.svg";
import TimeIcon from "./time.svg";
import LocationIcon from "./location.svg";

import { useLocate, saveProgress } from "utils/hooks";
import { FormattedMessage } from "react-intl";

const Introductory = ({ history, selectedInduction, isPreview = false, pageData }) => {
  
  var { current, next } = useLocate(
    selectedInduction,
    history?.location.pathname
  );

  if(isPreview)
  {
    current = pageData;
  }

  useEffect(() => {
    if(!isPreview)
       saveProgress(selectedInduction, history?.location.pathname);
    
    if(isPreview)
    {
      current = pageData;
    }

  }, []);

  const icons = {
    sound: SoundIcon,
    time: TimeIcon,
    location: LocationIcon,
  };

  return (
    <>
      <div className={classes.container} style={{ backgroundColor: "#44C032" }}>
        <Heading
          title={current?.title}
          titleId={isPreview ? '__' : current?.title_id}
          description={current?.data?.subtitle} 
          descriptionId={current?.data?.subtitle_id} 
          className={classes.Header}
        />
        <img
          src={current?.data?.image}
          className={classes.Image}
          alt="introductory"
        />

        <div className={classes.introductoryContainer}>
          <span className={classes.title}>
            <FormattedMessage
              id={isPreview ? '__' : current?.data["content_id"]}
              defaultMessage={current?.data?.content}
            />
          </span>
          <div className={classes.descriptionContainer}>
            {current?.data?.recommendations?.map((item) => (
              <div className={classes.itemContainer}>
                <img
                  alt={`${item.icon} Icon`}
                  className={classes.icon}
                  src={icons[item.icon]}
                />
                <span className={classes.description}>
                  <FormattedMessage
                    id={isPreview ? '__' : item.text_id}
                    defaultMessage={item.text}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {!isPreview && (
      <Footer>
        <Button
          formatID={"continue.button"}
          text={"Continue"}
          theme="blue"
          onClick={() => history.push(next?.url)}
        />
      </Footer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Introductory);
