import React, { useEffect, useState } from "react";
import RouteLeavingGuard from "../../../../components/UI/routeGuard";

import Menu from "components/Supervisor/menu";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getInductionDetailReq,updateInductionReq } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectUsers,
  makeSelectInductionDetail
} from "store/supervisor/selector";

import {
  makeSelectLanguages,
} from "store/language/selector";

import { isNil } from "lodash";
import ReactLoading from "react-loading";
import classes from "./editor.module.scss";
import queryString from "query-string";

import { getComponent } from "../../../../components/UI/section/componentHandler";
import Input from "../../../../components/Supervisor/input";
import Select from "react-select";

import pageIcon from "./icons/page_icon.png";
import pageIconSelected from "./icons/induction_check.svg";
import pageItemHeaderIcon from "./icons/pageitem_header.png";
import IconComponent from "../../../../components/UI/IconComponent";
import { Header } from "components";

//Forms for different page types
import Prequalification from "./Forms/prequalification";
import Introduction from "./Forms/introduction";
import Video from "./Forms/video";
import AllChaptersCompleteForm from "./Forms/allChaptersComplete";
import InductionCompleteForm from "./Forms/inductionComplete";
import RulesForm from "./Forms/rules";
import ChaptersForm from "./Forms/chapters";
import AssessmentForm from "./Forms/assessment"


//pages for preview
import Introductory from "../../../Introductory";
import Welcome from "../../../Welcome";
import LookAround from "../../../LookAround";
import Induction from "../../../Induction";
import AllChaptersComplete from "../../../AllChaptersComplete";
import SiteRules from "../../../SiteRules";
import Complete from "../../../Complete";
import AssesmentPage from "../../../Assessment";
import PostLogin from "../../../PostLogin";
import { select } from "redux-saga/effects";

import Modal from "components/Supervisor/modal";
import { previousFriday } from "date-fns";


const InductionEditor = ({
  history,
  loading,
  induction,
  getInductions,
  updateInduction,
  languages
}) => {
  const id = queryString.parse(window.location.search).id;

  const [editData, setEditData] = useState(false); //used for route guard when changing page
  const [isThereUnsavedEdits, setIsThereUnsavedEdits] = useState(false);
  const [itemToMoveTo, setItemToMoveTo] = useState({}); //This is used when user confirms the modal
 
  //Below are to update the induction content
  const [selectedPage, setSelectedPage] = useState({title: ''});
  const [selectedRule, setSelectedRule] = useState({title: ''});
  const [selectedSection, setSelectedSection] = useState({title: ''});
  
  //Below are only to update the preview
  const [selectedPagePreview, setSelectedPagePreview] = useState({title: ''});


  const [languageOptions, setLanguageOptions] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({code: 'en', name: "English"});
  const [translations, setTranslations] = useState({});
  const [previewCounter, setPreviewCounter] = useState(1);

  //creating new pages
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(0);
  const [pageType, setPageType] = useState();
  const [inductionPageData, setInductionPageData] = useState([]);


  useEffect(() => {
    if (languages?.length) {
      const dfList = languages.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });

      setLanguageOptions(dfList);

      //set the translation for the selected language
      var newTransalation = {}
      for(var i = 0; i < languages.length; i++)
      {
          if(induction?.translations)
          {
             var parsed = {};
             try{
               parsed = JSON.parse(induction?.translations);
               //console.log('parsed',parsed);
             }
             catch(err) {
              console.log('cannot parse json');
            }

             if(parsed[languages[i].code])
                newTransalation[languages[i].code] = {...JSON.parse(languages[i]?.data),...parsed[languages[i].code]}
          }
          else
            newTransalation[languages[i].code] = JSON.parse(languages[i]?.data);
      }

      setTranslations(newTransalation);

      console.log('initial translations',newTransalation);
      //console.log('induction translations',JSON.parse(induction?.translations)['en']);

    }
  }, [languages]);


  useEffect(() => {
    getInductions({id:id});
  }, []);

  useEffect(() => {
    console.log('induction:',induction);

    //set the first page as selected
    if(induction?.data?.pages.length > 0)
       updateSelectedPage(induction.data.pages[0]);
    
    setInductionPageData(induction.data.pages);

  }, [induction]);


  useEffect(() => {
    console.log('language changed',selectedLanguage);

    if(previewCounter > 0)
      return;

    //update the title of the page
    var updatedPageData = {...selectedPagePreview};

    console.log('language changed',updatedPageData);

    //change the title
    if(selectedPagePreview?.title_id)
       updatedPageData.title = translations[selectedLanguage.code][selectedPagePreview?.title_id];

    //change the subtitle
    if(selectedPagePreview?.data?.subtitle_id)
       updatedPageData.data = {...updatedPageData.data,subtitle: translations[selectedLanguage.code][selectedPagePreview?.data?.subtitle_id]};
    else
       updatedPageData.data = {...updatedPageData.data,subtitle: ''};


    //now the data based on page type
    if(updatedPageData?.type == 'post_login')
    {
        var questions = [...updatedPageData?.data?.questions];

        for(var i = 0; i < questions.length; i++)
        {
            if(questions[i].id)
            {
                if(translations[selectedLanguage.code][questions[i].id])
                   questions[i] = {...questions[i],name: translations[selectedLanguage.code][questions[i].id]}
                else
                   questions[i] = {...questions[i],name: ''}
            }
        }

        updatedPageData.data = {...updatedPageData.data,'questions': questions}

    }
    else if(updatedPageData?.type == 'introduction')
    {

      if(updatedPageData?.data?.content_id)
      {
          if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
             updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
          else
             updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
      }

      var recommendations = [...updatedPageData?.data?.recommendations];
  
      for(var i = 0; i < recommendations.length; i++)
      {
          if(recommendations[i].text_id)
          {
              if(translations[selectedLanguage.code][recommendations[i].text_id])
                recommendations[i] = {...recommendations[i],text: translations[selectedLanguage.code][recommendations[i].text_id]}
              else
              recommendations[i] = {...recommendations[i],text: ''}
          }
      }
  
      updatedPageData.data = {...updatedPageData.data,'recommendations': recommendations}
  
    }
    else if(updatedPageData?.type == 'all-chapters-complete')
    {
      if(updatedPageData?.data?.content_id)
      {
         if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
            updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
         else
            updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
      }

      if(updatedPageData?.data?.subcontent_id)
      {
         if(translations[selectedLanguage.code][updatedPageData?.data?.subcontent_id])
            updatedPageData.data = {...updatedPageData.data, subcontent: translations[selectedLanguage.code][updatedPageData?.data?.subcontent_id]};
         else
            updatedPageData.data = {...updatedPageData.data, subcontent: updatedPageData?.data?.subcontent_id};
      }
    }
    else if(updatedPageData?.type == 'site-rules')
    { 
        
      var rules = [...updatedPageData?.data?.rules];
  
      for(var i = 0; i < rules.length; i++)
      {
          if(rules[i].content_id)
          {
              if(translations[selectedLanguage.code][rules[i].content_id])
                rules[i] = {...rules[i],content: translations[selectedLanguage.code][rules[i].content_id]}
              else
                rules[i] = {...rules[i],content: rules[i].content_id}
          }
      }
  
      updatedPageData.data = {...updatedPageData.data,'rules': rules}
    }
    else if(updatedPageData?.type == 'complete')
    {
      if(updatedPageData?.data?.content_id)
      {
          if(translations[selectedLanguage.code][updatedPageData?.data?.content_id])
            updatedPageData.data = {...updatedPageData.data, content: translations[selectedLanguage.code][updatedPageData?.data?.content_id]};
          else
            updatedPageData.data = {...updatedPageData.data, content: updatedPageData?.data?.content_id};
      }
    } 

    
    //console.log('updatedPageData',updatedPageData);
 

    setSelectedPagePreview(updatedPageData);
    setPreviewCounter(2);

  }, [selectedLanguage]);


  function updateSelectedPage(item)
  {
    if(editData)
    {
      setIsThereUnsavedEdits(true);
      setItemToMoveTo(item);
    }
    else
    {
      setIsThereUnsavedEdits(false);
     
      setSelectedPage(item);
      setSelectedPagePreview(item); //to preview
      setSelectedRule({title: ''}); //reset
      setSelectedSection({title: ''}); //reset
      //console.log(item);
    }
  }

  function updateSelectedRule(item)
  {
     setSelectedRule(item);
     setSelectedSection({title: ''}); //reset
     //console.log(item);
  }

  function updateSelectedSection(item)
  {
     setSelectedSection(item);
     //console.log(item);
  }  

  function updateTranslation(language,key,content)
  {
      var newTranslation = {...translations}
      newTranslation[language][key] = content;
      setTranslations(newTranslation);

      setEditData(true); //trigger change checker

      console.log('translations',newTranslation);
  }

  function handleSubmit()
  {
     //This is our gigantic induction save function so we will go step by step
     
    //update page data
    var data = { ...induction.data }
    var pageData = [...inductionPageData]

    for(var i = 0; i < inductionPageData.length; i++)
    {
        if(inductionPageData[i].id == selectedPage.id)
        {
            //console.log('found id: '+ selectedPage.id);
            pageData[i] = {...selectedPage};
        }


        if(inductionPageData[i].data.rules)
        {
          var ruleData = [...inductionPageData[i].data.rules]
          for(var j = 0; j < inductionPageData[i].data.rules.length; j++)
          {
              if(inductionPageData[i].data.rules[j].id == selectedRule.id && selectedRule.id > 0)
              {
                  //console.log('found id: '+ selectedRule.id);
                  ruleData[j] = {...selectedRule};
              }
              
              if(inductionPageData[i].data.rules[j]?.sections)
              {
                
                var updatedSections = [...inductionPageData[i].data.rules[j].sections];
                for(var k = 0; k < updatedSections.length; k++)
                {
                    if(inductionPageData[i].data.rules[j].sections[k]?.header_id == selectedSection?.header_id
                      && inductionPageData[i].data.rules[j].sections[k]?.title_id == selectedSection?.title_id
                    )
                    {
                      updatedSections[k] = selectedSection;
                    }

                    ruleData[j] = {...ruleData[j],sections:updatedSections};

                }
              }

          }
          
          //console.log('ruleData', ruleData);
          //doesn't work for now
          var updatedData = {...pageData[i],data:{...pageData[i].data, rules: ruleData}}
          pageData[i] = {...updatedData};
          //pageData[i] = {...pageData[i], 'rules': ruleData};

        }
    }

    data.pages = pageData;


    console.log('data.pages',data.pages);
    
    //DO NOT OPEN UNTIL YOU ARE SURE DATA IS ASSEMBLED CORRECTLY!
    //translations only for now
    updateInduction({'id': id, 'data': JSON.stringify(data), 'translations': JSON.stringify(translations)})

    //reset the status
    setEditData(false);
    setIsThereUnsavedEdits(false);

  }
  
  //function that is called by the special forms
  function updatePageData(val,isPreview=false)
  {
    setEditData(true); //trigger change checker

    console.log('update page data called',val);

    var currentData = {};

    if(isPreview)
       currentData = {...selectedPagePreview}
    else
       currentData = {...selectedPage}

    if(val?.key)
    {
      var updatedPage = {...currentData, data:{...currentData.data, 
        [val?.key]: val?.value
      }}
    }
    else
    {
      var updatedPage = {...currentData, data:{...currentData.data, 
                                                image_id: val?.image_id,
                                                image: val?.image
                                              }}
    }
    console.log('updatedPage',updatedPage);
    
    if(isPreview)
      setSelectedPagePreview(updatedPage);
    else
      setSelectedPage(updatedPage);
  }

  function prepareTranslatedHotspots(hotspots)
  {
     var newHotspots = [...hotspots];

     newHotspots = newHotspots.map((item) => { return {
      ...item,
      name: translations[selectedLanguage.code][item?.name_id] ? translations[selectedLanguage.code][item?.name_id]: '-',
      content: translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '-'

     } } );

     return newHotspots;
  }

  function prepareTranslatedFlipcards(cards)
  {
    var newCards = [...cards];

    newCards = newCards.map((item) => { return {
      ...item,
      title: translations[selectedLanguage.code][item?.title_id],
      front_title: translations[selectedLanguage.code][item?.front_title_id],
      front: translations[selectedLanguage.code][item?.front_id],
      back_title: translations[selectedLanguage.code][item?.back_title_id],
      back: translations[selectedLanguage.code][item?.back_id]

     } } );

     return newCards;
  }

  function prepareTranslatedCarousel(content)
  {
    var newCards = [...content];

    newCards = newCards.map((item) => { return {
      ...item,
      title: translations[selectedLanguage.code][item?.title_id],
      content:  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '',

     } } );

     return newCards;
  }


  function prepareTranslatedCardsort(content)
  {
    var newCards = [...content];

    newCards = newCards.map((item) => { return {
      ...item,
      name: translations[selectedLanguage.code][item?.name_id] ? translations[selectedLanguage.code][item?.name_id] : '-',
      content:  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '-',
      categories: item?.categories.map((catItem) => { return {...catItem, name: translations[selectedLanguage.code][catItem?.name_id] ? translations[selectedLanguage.code][catItem?.name_id] : '-' }})

     } } );

     return newCards;
  }

  //Change Modal actions
  function closeModal()
  {
     setIsThereUnsavedEdits(false);
  }

  function modalAction()
  {
     //move to the next page without saving
     setIsThereUnsavedEdits(false);
     setEditData(false);
     updateSelectedPage(itemToMoveTo);
     
  }

  //create modal actions
  function closeCreateModal()
  {
     setIsCreateModalOpen(false);
  }

  function createPage()
  {
    var pageData = [...inductionPageData];

    //find the highest id
    var nextId = 0;
    for(var i=0; i < pageData.length; i++)
    {
        if(pageData[i].id > nextId)
           nextId = pageData[i].id;
    }
    nextId +=1;

     var newPageData = {
       id: nextId,
       type: pageType,
       url: '/' + pageType + '-' + nextId,
       title: '',
       title_id: 'induction.' + id + '.page.' + nextId + '.title',
       menu_name: "",
       menu_name_id: 'induction.' + id + '.page.' + nextId + '.menu.name',
       public: true,
       show_in_menu: true,
       data: {
         subtitle: '',
         subtitle_id: 'induction.' + id + '.page.' + nextId + '.subtitle',
       } 
     }

     if(pageType == 'introduction')
     {
        newPageData['data'] = {
          ...newPageData['data'],
          'content': '',
          'content_id': 'induction.' + id + '.page.' + nextId + '.content',
          'image': "https://keepsafe.work/images/02.01_welcome_A.jpeg",
          'recommendations': [
            {
              "icon": "sound",
              "text": "You will need sound for this induction",
              "text_id": 'induction.' + id + '.page.' + nextId + '.recommendation.1'
            },
            {
              "icon": "time",
              "text": "The time required is approx. 7 mins.",
              "text_id": 'induction.' + id + '.page.' + nextId + '.recommendation.2'
            }   
          ]

        }
     }
     else if(pageType == 'all-chapters-complete')
     {
          newPageData['data'] = {
           ...newPageData['data'],
          content: "You now understand our life saving rules. Let us walk you through a few last notes before we let you get on with it!",
          content_id: 'induction.' + id + '.page.' + nextId + '.content',
          subcontent: "Tap the next button below to proceed.",
          subcontent_id: 'induction.' + id + '.page.' + nextId + '.subcontent',
 
         }
     }
     else if(pageType == 'welcome')
     {
         newPageData['data'] = {
           ...newPageData['data'],
           video: [
                    {
                        "language": "eng",
                        "url": "https://vimeo.com/videoId",
                        "transcript": "Lorem ipsum dolor"
                    }
          ],
          video_subtitles: [
            "eng"
          ]
 
         }
     }
     else if(pageType == 'site-rules')
     {
        newPageData['data'] = {
          ...newPageData['data'],
          rules: [
                  {
                      "content": "<p>Lorem ipsum dolor sit amet.</p>",
                      "content_id": 'induction.' + id + '.page.' + nextId + '.content.1',
                      "image": ""
                  },
                  {
                      "content": "<p>Aut mollitia repudiandae qui consequatur nisi qui fugit similique ut omnis nesciunt.</p>",
                      "content_id": 'induction.' + id + '.page.' + nextId + '.content.2',
                      "image": ""
                  },
                  {
                      "content": "<p>Cum iusto vero ut natus vitae in deserunt iure qui rerum consequatur ut temporibus nemo ea iure necessitatibus.</p>",
                      "content_id": 'induction.' + id + '.page.' + nextId + '.content.3',
                      "image": ""
                  },
                ]
        }
     }
     else if(pageType == 'complete')
     {
           newPageData['data'] = {
            ...newPageData['data'],
           content: "And remember, if you are ever in doubt - ask your Supervisor.",
           content_id: 'induction.' + id + '.page.' + nextId + '.content',
  
          }
     }
     else if(pageType == 'post_login')
     {
          //fix type because it doesn't match the url
          newPageData['url'] = '/post-login-' + nextId;

          newPageData['data'] = {
            ...newPageData['data'],
            questions: [
              {
                 id: 'induction.' + id + '.page.' + nextId + '.question.1',
                 name: "Question 1"
              },
              {
                id: 'induction.' + id + '.page.' + nextId + '.question.2',
                name: "Question 2"
              }
            ]
  
          }
     }

     pageData.push(newPageData);

     console.log('newPage',newPageData);

     setInductionPageData(pageData);

     setIsCreateModalOpen(false);
  }



  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div className={classes.InductionEditorContainer}>

          <div className={classes.inductionMenuWrapper}>  
            <h3>Induction</h3>
            
            <div className={classes.pageItemWrapper}>
                {inductionPageData.map((item) => (
                <div>              
                  <div className={selectedPage.id == item.id ? classes.pageItemSelected : classes.pageItem} onClick={() => updateSelectedPage(item)}>
                    <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
                    <img src={selectedPage.id == item.id ? pageIconSelected : pageIcon} />
                    <span className={classes.pageItemTitle}>{ translations[selectedLanguage.code] ? translations[selectedLanguage.code][item.title_id] ? translations[selectedLanguage.code][item.title_id]: item?.title : item?.title}</span>
                  </div>

                  {(item.data?.rules && item.id == selectedPage.id && item.type=='rules') && (
                    <div className={classes.ruleItemWrapper}>                     
                      {item.data?.rules?.map((item) => (
                         <div>
                          <div onClick={() => updateSelectedRule(item)} className={classes.ruleItem} style={{background:item?.background, color: item?.textColor,borderColor: item?.title == selectedRule.title ? '#44C032' : '#fff'}}>
                            <div className={classes.pageItemHeader}> <img src={pageItemHeaderIcon}/> </div>
                            
                            <div className={classes.ruleIconWrapper}>
                              <div style={{backgroundColor: !isNil(item?.iconBackground) ? item?.iconBackground : item?.color}}>
                                {!isNil(item?.iconType) ? (
                                  <IconComponent
                                    icon={item?.iconType}
                                    color={item?.iconColor ? item?.iconColor : "white"}
                                    size={"80%"}
                                  />
                                ) : (
                                  <img src={item?.icon} />
                                )}
                              </div>
                            </div>
                            
                            {item.title}
                          </div>

                          {item?.title == selectedRule.title && item.sections?.map((sectionItem) => (
                            <div onClick={() => updateSelectedSection(sectionItem)} className={classes.sectionItem} style={{background:item?.background, color: item?.textColor,borderColor: sectionItem?.title == selectedSection.title ? '#44C032' : '#fff'}}>
                               {sectionItem.title_id ? translations[selectedLanguage.code][sectionItem.title_id] : sectionItem.title}
                            </div>
                          ))}

                          {item?.title_id == selectedRule.title_id && (
                            <button
                            id={"save"}
                            type="submit"
                            style={{width: '100px'}}
                            onClick={function(){
                                var sections = [...selectedRule.sections];

                                const newId = sections.length + 1;
                                sections.push({
                                    "title": "New Section",
                                    "title_id": "induction." + id+ ".page."+selectedPage.id+".rule."+selectedRule.id+".section."+newId+".title",
                                    "data": {
                                      "components": []
                                    }
                                });

                                console.log('sections',sections);

                                //update induction data
                                var pageData = [...inductionPageData];

                                for(var i=0; i < pageData.length; i++)
                                {
                                    if(pageData[i].id  == selectedPage.id)
                                    {
                                        
                                        var rules = [...pageData[i].data.rules];
                                        for(var j=0; j < rules.length; j++)
                                        {
                                            if(rules[j].id == selectedRule.id)
                                            {
                                              rules[j] = {...rules[j],sections:sections};
                                            }
                                        }
                                        pageData[i] = {...pageData[i],data: {...pageData[i].data, rules: rules}}
                                    }
                                }

                                setInductionPageData(pageData);
                                
                            }}
                            disabled={!isNil(loading) ? false : loading}
                            className={classes.darkButton}
                            >
                            +
                            </button>
                          )}
                          

                              

                          
                          
                          </div>
                      
                      ))}      
                    </div>
                  )}
                </div>
                ))}
            </div>

            
            
            {/*selectedRule.sections && (
              <div className={classes.sectionItemWrapper}>
                {selectedRule.sections?.map((item) => (
                
                    <div onClick={() => updateSelectedSection(item)} className={classes.sectionItem}>{item.title}</div>
                
                ))}      
              </div>
                )*/}
          </div>
            
          <div className={classes.pageEditorWrapper}>                 
            <h3>Editor</h3>
            <div className={classes.pageEditorContainer}>  

                  {languageOptions && (
                    <div className={classes.Container}>
                      <label className={classes.BlockLabel}>
                        Language
                      </label>
                      <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Select Language"}
                        onChange={(val) => {
                          //setSelectedOption(val.value);
                          setPreviewCounter(0);

                          setSelectedLanguage({
                            code: val.value,
                            name: val.label,
                          });
                          
                          /*
                          //title
                          var newPageUpdate = { ...selectedPagePreview }
                          newPageUpdate.title = translations[val.value][selectedPage?.title_id]
                          setSelectedPagePreview(newPageUpdate);

                          //subtitle
                          updatePageData({key:'subtitle',value:translations[val.value][selectedPage?.data?.subtitle_id]},true);
                          */

                          //setTimeout(function(){ setPreviewCounter(2); })
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={languageOptions.filter(
                          (option) => option.value === selectedLanguage?.code
                        )}
                        options={languageOptions}
                        isSearchable={false}
                      />
                    </div>
                  )} 

                {!selectedSection.title && (
                <Input
                  hasTooltip={true}
                  order="column"
                  value={selectedPage?.title_id ? translations[selectedLanguage.code][selectedPage?.title_id] ? translations[selectedLanguage.code][selectedPage?.title_id] : '' : '' }
                  name={"Heading Text"}
                  characterLimit={250}
                  onChange={(e) => {
                    var newPageUpdate = { ...selectedPagePreview }
                    newPageUpdate.title = e.target.value;
                    
                    //don't update the real data only preview
                    //setSelectedPage(newPageUpdate);
                    setSelectedPagePreview(newPageUpdate);

                    //and translation
                    updateTranslation(selectedLanguage.code, selectedPage?.title_id, e.target.value);
                  }}
                  label={"Heading Text"}
                  placeholder={"Heading Text"}
                  error={""}
                  tooltipText={
                    "Heading text, also known as the page name"
                  }
                />
                )}
                
                {!selectedSection.title && (
                <Input
                  hasTooltip={true}
                  order="column"
                  value={selectedPage?.data?.subtitle_id ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] ? translations[selectedLanguage.code][selectedPage?.data?.subtitle_id] : '' : ''}
                  name={"Supporting Text"}
                  characterLimit={250}
                  onChange={(e) => {
                    updatePageData({key:'subtitle',value:e.target.value},true); //only preview
                    updateTranslation(selectedLanguage.code, selectedPage?.data?.subtitle_id, e.target.value);
                  }}
                  label={"Supporting Text"}
                  placeholder={"Supporting Text"}
                  error={""}
                  tooltipText={
                    "Supporting text, appears underneath the heading"
                  }
                />
                )}



                {selectedRule.title && !selectedSection.title  && (
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={selectedRule?.title_id ? translations[selectedLanguage.code][selectedRule?.title_id] ? translations[selectedLanguage.code][selectedRule?.title_id] : '' : ''}
                    name={"Chapter Name"}
                    characterLimit={250}
                    onChange={(e) => {
                      var newChapterUpdate = { ...selectedRule }
                      newChapterUpdate.title = e.target.value;
                      setSelectedRule(newChapterUpdate);
                      updateTranslation(selectedLanguage.code, selectedRule?.title_id, e.target.value);
                    }}
                    label={"Chapter Name"}
                    placeholder={"Chapter Name"}
                    error={""}
                    tooltipText={
                      "Chapter Name"
                    }
                  />
                )}

                {selectedSection.title && (
                  <Input
                    hasTooltip={true}
                    order="column"
                    value={selectedSection?.title_id ? translations[selectedLanguage.code][selectedSection?.title_id] ? translations[selectedLanguage.code][selectedSection?.title_id] : '' : ''}
                    name={"Section Name"}
                    characterLimit={250}
                    onChange={(e) => {
                      var newSectionUpdate = { ...selectedSection }
                      newSectionUpdate.title = e.target.value;
                      setSelectedSection(newSectionUpdate);
                      updateTranslation(selectedLanguage.code, selectedSection?.title_id, e.target.value);
                    }}
                    label={"Section Name"}
                    placeholder={"Section Name"}
                    error={""}
                    tooltipText={
                      "Section Name"
                    }
                  />
                )}
                
                {/* Here we bring the forms based on selected page type */} 

                {selectedPage.type == 'post_login' && (
                  <Prequalification page={selectedPagePreview} updatePageData={updatePageData} 
                                    translations={translations} 
                                    selectedLanguage={selectedLanguage} 
                                    updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'introduction' && (
                  <Introduction page={selectedPagePreview} updatePageData={updatePageData} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'welcome' && (
                  <Video page={selectedPagePreview} updatePageData={updatePageData} />
                )}

                {selectedPage.type == 'all-chapters-complete' && (
                  <AllChaptersCompleteForm page={selectedPagePreview} updatePageData={updatePageData} 
                                           translations={translations} 
                                           selectedLanguage={selectedLanguage} 
                                           updateTranslation={updateTranslation}                  
                  />
                )}

                {selectedPage.type == 'complete' && (
                  <InductionCompleteForm page={selectedPagePreview} updatePageData={updatePageData}
                                         translations={translations} 
                                         selectedLanguage={selectedLanguage} 
                                         updateTranslation={updateTranslation}
                  />
                )}

                {selectedPage.type == 'assessment' && (
                  <AssessmentForm page={selectedPagePreview} updatePageData={updatePageData}
                                         translations={translations} 
                                         selectedLanguage={selectedLanguage} 
                                         updateTranslation={updateTranslation}
                                         setEditData={setEditData}
                  />
                )}

                {selectedPage.type == 'site-rules' && (
                  <RulesForm page={selectedPagePreview} updatePageData={updatePageData} 
                             translations={translations} 
                             selectedLanguage={selectedLanguage} 
                             updateTranslation={updateTranslation}
                  
                  />
                )}

                {selectedPage.type == 'rules' && selectedSection.title && (
                  <ChaptersForm page={selectedPagePreview} 
                                chapter={selectedRule} 
                                section={selectedSection} 
                                updatePageData={updatePageData} 
                                setSelectedSection={setSelectedSection} 
                                translations={translations} 
                                selectedLanguage={selectedLanguage} 
                                updateTranslation={updateTranslation}
                                setEditData={setEditData}
                                induction_id={id}
                  />
                )}


                <br />
                <button
                    id={"save"}
                    type="submit"
                    style={{width: '250px'}}
                    onClick={handleSubmit}
                    disabled={!isNil(loading) ? false : loading}
                    className={classes.darkButton}
                  >
                    Save
                </button>






            </div>
          </div>

          <div className={classes.pagePreviewWrapper}>
            <h3>Preview</h3>
            <div className={classes.pagePreviewContainer}>
              <div className={classes.pagePreviewContent}>
                  <div className={classes.pagePreviewHeaderWrapper}>
                    <Header
                    menu={false}
                    open={false}
                    setOpen={function(){}}
                  />
                  </div>

                  {/* These are different page components for preview - opened in preview mode */}

                  {selectedPage?.type == "introduction" && previewCounter > 0 && (
                    <div>
                        <Introductory selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "welcome" && (
                    <div>
                        <Welcome selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "take-a-look" && (
                    <div>
                        <LookAround selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "all-chapters-complete" && (
                    <div>
                        <AllChaptersComplete selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "site-rules" && (
                    <div>
                        <SiteRules selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}

                  {selectedPage?.type == "complete" && previewCounter > 0 &&  (
                    <div>
                        <Complete selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}


                  {selectedPage?.type == "assessment" && (
                    <div>
                        <AssesmentPage selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}


                  {selectedPage?.type == "post_login" && (
                    <div>
                        <PostLogin selectedInduction={induction} isPreview={true} pageData={selectedPagePreview} />
                    </div>
                  )}




                  {selectedPage?.type == "rules" && selectedSection?.title == '' && (
                      
                    <div>
                        <Induction selectedInduction={induction} 
                                   induction={induction} isPreview={true} 
                                   pageData={selectedPagePreview} 
                                   history={history} 
                        />
                    </div>
                      
                  )}

                  {selectedSection && previewCounter > 0 && (
                    <div>
                        {selectedSection.data?.components?.map((item) =>
                            
                          item.type != 'card_sort__' ? getComponent(item.type, {
                            ...item,
                            title: translations[selectedLanguage.code][item?.title_id] ? translations[selectedLanguage.code][item?.title_id] : '-',
                            header: translations[selectedLanguage.code][item?.header_id] ? translations[selectedLanguage.code][item?.header_id] : '-',
                            button_label: translations[selectedLanguage.code][item?.button_label_id],
                            conclusion_title: translations[selectedLanguage.code][item?.conclusion_title_id],
                            conclusion_content: translations[selectedLanguage.code][item?.conclusion_content_id],
                            success_modal_title: translations[selectedLanguage.code][item?.success_modal_title_id],
                            success_modal_description: translations[selectedLanguage.code][item?.success_modal_description_id],
                            category_title: translations[selectedLanguage.code][item?.category_title_id] ? translations[selectedLanguage.code][item?.category_title_id] : '-',
                            content: item.type != 'carousel' ? translations[selectedLanguage.code][item?.content_id] : prepareTranslatedCarousel(item.content),
                            hotspots: item.type == 'hotspots' ? prepareTranslatedHotspots(item.hotspots) : '',
                            cards: item.type == 'flipcard' ? prepareTranslatedFlipcards(item.cards) : item.type == 'card_sort' ? prepareTranslatedCardsort(item.cards) : item?.cards
                          }, () => {},true) : ''
                          
                        )}
                    </div>
                  )}

              </div>
            </div>
          </div>

          
          <div className={classes.buttonContainer}>
            <div className={classes.buttonWrapper}>
              <div style={{ justifyContent: "flex-end" }} className={classes.flex}>

                <button
                  disabled={false}
                  className={classes.darkButton}
                  onClick={() => {
                    if(induction?.status == 1)
                      updateInduction({'id': id, 'status': 0})
                    else
                      updateInduction({'id': id, 'status': 1})
                  }}
                >
                  {induction?.status == 1 ? "Move to Draft" : "Publish"}
                </button>
                
              </div>
              &nbsp; 
              <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
                <button
                  disabled={false}
                  className={classes.darkButton}
                  onClick={() => {
                     setIsCreateModalOpen(true);
                  }}
                >
                  {"Create a page"}
                </button>
              </div>

            </div>
          </div>          


          

            
        </div>
        

      )}

      <RouteLeavingGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        confirmModal={true}
        when={editData == true}
        // Navigate function
        navigate={(path) => history.push(path)}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          return true;
        }}
      />

      <Modal
        open={isThereUnsavedEdits}
        cancel={closeModal}
        action={modalAction}
        deleteTitle={"You have unsaved changes"}
        deleteDescription={
          "If you continue, your changes will be lost. Do you want to continue?"
        }
        buttonTitle={"Continue"}
      />

      <Modal
        open={isCreateModalOpen}
        cancel={closeCreateModal}
        action={createPage}
        type={'editor'}
        buttonTitle={"Create"}
        updateData={function(key,value){
           if(key == 'type')
              setPageType(value);
        }}
      />

    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  induction: makeSelectInductionDetail(),
  languages: makeSelectLanguages(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getInductions: (val) => dispatch(getInductionDetailReq(val)),
    updateInduction: (val) => dispatch(updateInductionReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InductionEditor);
