// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_ASSESSMENT,
  UPDATE_ASSESSMENT_ERROR,
  UPDATE_ASSESSMENT_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* updateAssessmentRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    
    //console.log('all data',values);

    params.append("assessment_id", values.assessment_id);

    //if (values.name != '')
    //    params.append("name", values.name);
    
    if(values?.update_type)
      params.append("type", values?.update_type);

    if(values?.option_id)
       params.append("option_id", values?.option_id);
    
    if(values.hasOwnProperty("image"))
       params.append("image", values?.image);

    if(values.hasOwnProperty("is_correct"))
       params.append("is_correct", values?.is_correct);

    if(values.hasOwnProperty("question_id"))
      params.append("question_id", values?.question_id);

    if(values.hasOwnProperty("question_type"))
      params.append("question_type", values?.question_type);

    const response = yield call(axios.put, `/assessment/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_ASSESSMENT_ERROR,
        data: response.error,
      });
    } else {
      console.log("response", response);
      yield put({
        type: UPDATE_ASSESSMENT_SUCCESS,
        data: response.data,
      });
    }
    //yield call(success, `Induction Updated Succesfully`);
  } catch (error) {
    yield put({
      type: UPDATE_ASSESSMENT_ERROR,
      data: error,
    });
  }
}

export default function* updateAssessmentRequestSaga() {
  yield takeLatest(UPDATE_ASSESSMENT, updateAssessmentRequest);
}
