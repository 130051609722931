import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import RightIcon from "./right-icon.svg";
import LeftIcon from "./left-icon.svg";
import classes from "./carousel.module.scss";
import Button from "components/UI/button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RestartIcon from "../../../assets/icons/ks_refresh.svg";
import cn from "classnames";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(classes.Arrow, classes.RightArrow, className)}
      style={{
        ...style,
        zIndex: 1,
        right: "-30px",
      }}
      onClick={onClick}
    >
      <img
        src={LeftIcon}
        className={cn(classes.Svg, classes.flexStart)}
        alt="left-arrow"
      />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(classes.Arrow, classes.LeftArrow, className)}
      style={{
        ...style,
        zIndex: 1,
        left: "-30px",
      }}
      onClick={onClick}
    >
      <img
        src={RightIcon}
        className={cn(classes.Svg, classes.flexEnd)}
        alt="right-arrow"
      />
    </div>
  );
}

const Carousel = ({ config, interactive, setFooterVisibilityReq,isPreview }) => {
  const sliderRef = useRef(null);
  const [isEnded, setIsEnd] = useState(false);
  const [currentIndex, setIndex] = useState(0);

  useEffect(() => {
    if (interactive === true) {
      setFooterVisibilityReq({ isFooterVisible: false });
    }
  }, [interactive]);

  useEffect(() => {
    if (isEnded) {
      setFooterVisibilityReq({ isFooterVisible: true });
    }
  }, [isEnded]);

  const settings = {
    dots: true,
    infinite: false,
    draggable: true,
    mobileFirst: true,
    adaptiveHeight: false,
    lazyLoad: true,
    height: 550,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          nextArrow: null,
          prevArrow: null,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      return setIndex(newIndex);
    },
  };

  useEffect(() => {
    if (!isNil(config) && currentIndex === config?.length - 1) {
      setIsEnd(true);
    }
  }, [config, currentIndex]);

  return (
    <div className={classes.Container}>
      <div className={classes.CarouselContainer}>
        <div className={classes.sliderwrapper}>
          <Slider {...settings} ref={sliderRef}>
            {config.map((con, idx) => (
              <div className={classes.slide}>
                <div className={classes.Carousel}>
                  <div className={classes.Header}>
                    <FormattedMessage
                      defaultMessage={con.title}
                      id={isPreview ? con?.title ? con?.title : '_'  : con?.title_id}
                    />
                  </div>
                  {!isNil(con?.image) && con?.image && (
                    <div className={classes.aspectRatioWrapper}>
                      <div className={classes.aspectRatioBox}>
                        <img
                          src={con?.image}
                          className={classes.Image}
                          alt="img"
                        />
                      </div>
                    </div>
                  )}
                  <div className={classes.Content}>
                    <FormattedHTMLMessage
                      value={con?.content}
                      id={isPreview ? con?.content ? con?.content : '_'  : con?.content_id}
                      description={con?.content}
                    />
                  </div>
                  {(idx === 0 || idx === config.length - 1) && (
                    <div className={classes.Button}>
                      <Button
                        id={idx === 0 ? "continue-button" : "restart-button"}
                        text={idx === 0 ? "Continue" : "Start Again"}
                        formatID={
                          idx === 0 ? "continue.button" : "start-again.button"
                        }
                        /*theme={idx === config?.length - 1 && "light"}*/
                        theme={"blue"}
                        textColor={idx === config?.length - 1 && "#fff"}
                        icon={idx === config?.length - 1 && RestartIcon}
                        onClick={() => {
                          if (idx === 0) {
                            if (!isNil(sliderRef?.current)) {
                              return sliderRef?.current?.slickNext();
                            }
                          } else {
                            if (!isNil(sliderRef?.current)) {
                              return sliderRef?.current?.slickGoTo(0);
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
