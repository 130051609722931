import React,{useState,useEffect} from "react";
import Input from "../../../../../components/Supervisor/input";
import ImageUpload from "../../../Hazards/components/imageUpload";
import classes from "../editor.module.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import Select from "react-select";

import {
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
import {
    makeSelectInductionImage,
    makeSelectLoading,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";


const ChaptersForm = ({
induction_id,
page,
updatePageData, 
chapter, 
section,
inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
setSelectedSection,
translations,
selectedLanguage,
updateTranslation,
setEditData

}) => {


const [rules, setRules] = useState(page?.data?.rules);
const [initialState, setInitialState] = useState(true);
const [selectedComponent, setSelectedComponent] = useState(0);
const [selectedKey, setSelectedKey] = useState('image_id');
const [selectedCard, setSelectedCard] = useState(0);

const [selectedBlockType, setSelectedBlockType] = useState({});
const itemtypes = {
    'image_text_full': 'Fulscreen Image Block',
    'image_text_regular': 'Image & Text Block',
    'flipcard': 'Flip Card',
    'hotspots': 'Hotspots',
    'card_sort': 'Card Sort',
    'carousel': 'Carousel'
                
};

const updateRule = (item, value) => {
    var updatedRules = [...rules];
    updatedRules[item] = {...rules[item], content:value};
    setRules(updatedRules);
    updatePageData({key:'rules',value:updatedRules});
}

useEffect(() => 
{
    if(initialState)
       return;

    if (inductionImage?.url) 
    {
       setEditData(true);
       
       var newComponentUpdate = [ ...section.data.components ]
                            
       for(var j = 0; j < newComponentUpdate.length; j++)
       {
           if(j == selectedComponent)
           {
              if(newComponentUpdate[j]?.type == 'flipcard')
              {
                if(selectedKey == 'front_image')
                { 
                    var cardsUpdate = [ ...newComponentUpdate[j].cards];
                    cardsUpdate[selectedCard] = {...cardsUpdate[selectedCard], 
                                                 front_image_id:inductionImage?.id,
                                                 front_image: inductionImage?.url
                                                };

                    newComponentUpdate[j] = {...newComponentUpdate[j],cards: cardsUpdate};
                }
                else if(selectedKey == 'back_image')
                { 
                    var cardsUpdate = [ ...newComponentUpdate[j].cards];
                    cardsUpdate[selectedCard] = {...cardsUpdate[selectedCard], 
                                                    back_image_id:inductionImage?.id,
                                                    back_image: inductionImage?.url
                                                };

                    newComponentUpdate[j] = {...newComponentUpdate[j],cards: cardsUpdate};
                }
              }
              else if(newComponentUpdate[j]?.type == 'carousel')
              {
                  if(selectedKey == 'image')
                  { 
                      var cardsUpdate = [ ...newComponentUpdate[j].content];
                      cardsUpdate[selectedCard] = {...cardsUpdate[selectedCard], 
                                                   image_id:inductionImage?.id,
                                                   image: inductionImage?.url
                                                  };
  
                      newComponentUpdate[j] = {...newComponentUpdate[j],content: cardsUpdate};
                  }
              }
              else if(newComponentUpdate[j]?.type == 'hotspots')
              {
                 //hotspot image use the key 'background' so it's different
                 newComponentUpdate[j] = {...newComponentUpdate[j],background_id: inductionImage?.id, background: inductionImage?.url};
              }
              else
                 newComponentUpdate[j] = {...newComponentUpdate[j],image_id: inductionImage?.id, image: inductionImage?.url};
           }
       }
       
       var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
       setSelectedSection(updatedData);

    }
  }, [inductionImage]);

  //console.log('components',section?.data?.components);

  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Blocks List</h4>
        
        {section?.data?.components.map((item,index) =>
                       
            <> 
            
            <div className={classes.qualificationCard}>{itemtypes[item?.type]}</div>

            <Input
                hasTooltip={true}
                order="column"
                value={item?.title_id ? translations[selectedLanguage.code][item?.title_id] ? translations[selectedLanguage.code][item?.title_id] : '' : ''}
                name={"component_title_"+index}
                characterLimit={120}
                onChange={(e) => {
                    var newComponentUpdate = [ ...section.data.components ]
                            
                    for(var j = 0; j < newComponentUpdate.length; j++)
                    {
                        if(j == index)
                            newComponentUpdate[j] = {...newComponentUpdate[j],title:e.target.value};

                    }
                    
                    var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                    setSelectedSection(updatedData);
                    

                    //update translation
                    updateTranslation(selectedLanguage.code,item?.title_id,e.target.value);
                }}
                label={"Title"}
                placeholder={"Title here"}
                error={""}
                tooltipText={
                "Title text"
                }
            />
            <br />
            
            {item?.type == 'image_text_full' && (
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />
            )}

            {item?.type == 'image_text_regular' && (
                <div>
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {

                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                        }
                        }}
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.header_id ? translations[selectedLanguage.code][item?.header_id] ? translations[selectedLanguage.code][item?.header_id] : '' : ''}
                        name={item?.header_id ? item?.header_id : "component_header_"+index}
                        characterLimit={120}
                        onChange={(e) => {
                            /*
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],header:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           */

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.header_id,e.target.value);
                        }}
                        label={"Header"}
                        placeholder={"header here"}
                        error={""}
                        tooltipText={
                        "Header text"
                        }
                    />


                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : ''  : ''}
                        name={item?.content_id ? item?.content_id : "component_content_"+index}
                        characterLimit={250}
                        onChange={(e) => {
                            /*
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           */

                           //update translation
                           updateTranslation(selectedLanguage.code, item?.content_id, e.target.value);
                        }}
                        label={"Content"}
                        placeholder={"Content here"}
                        error={""}
                        tooltipText={
                        "Content text"
                        }
                    />

                </div>
            )}

            {item?.type == 'hotspots' && (
              <div>  
               <br />
               <h4 className={classes.formHeading}>Modals</h4>

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ?  translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '' : ''}
                    name={item?.content_id ? item?.content_id : "component_content_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.content_id, e.target.value);
                    }}
                    type="textarea"
                    label={"Intro Modal Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content text for the hotspot intro modal. Supports HTML tags"
                    }
               />

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.button_label_id ? translations[selectedLanguage.code][item?.button_label_id] ? translations[selectedLanguage.code][item?.button_label_id] : '' : ''}
                    name={item?.button_label_id ? item?.button_label_id : "component_button_label_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],button_label:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.button_label_id, e.target.value);
                    }}
                    label={"Intro Modal Button Label"}
                    placeholder={"Button Label"}
                    error={""}
                    tooltipText={
                    "This is the button label for the hotspot intro modal"
                    }
               />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.conclusion_title_id ? translations[selectedLanguage.code][item?.conclusion_title_id] ? translations[selectedLanguage.code][item?.conclusion_title_id] : '' : ''}
                    name={item?.conclusion_title_id ? item?.conclusion_title_id : "component_conclusion_title_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],conclusion_title:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.conclusion_title_id, e.target.value);
                    }}
                    label={"Conclusion Title"}
                    placeholder={"Conclusion Title"}
                    error={""}
                    tooltipText={
                    "This is the conclusion modal title. Appears when all the hotspots are clicked."
                    }
               />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.conclusion_content_id ? translations[selectedLanguage.code][item?.conclusion_content_id] ? translations[selectedLanguage.code][item?.conclusion_content_id] : '' : ''}
                    name={item?.conclusion_content_id ? item?.conclusion_content_id : "component_conclusion_content_"+index}
                    characterLimit={250}
                    onChange={(e) => {
                        /*
                        var newComponentUpdate = [ ...section.data.components ]
                        
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],conclusion_content:e.target.value};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        */

                        //update translation
                        updateTranslation(selectedLanguage.code, item?.conclusion_content_id, e.target.value);
                    }}
                    type="textarea"
                    label={"Conclusion Content"}
                    placeholder={"Conclusion Content"}
                    error={""}
                    tooltipText={
                    "This is the conclusion modal content. Appears when all the hotspots are clicked. Supports HTML."
                    }
               />


                <ImageUpload
                    isSuccess={inductionImageSuccess}
                    isUploading={inductionImageLoading}
                    isError={inductionImageError}
                    onDelete={() => {
                    //setFieldValue("image_url", null);
                    //setChangeCount((val) => val + 1);
                    }}
                    imageUrl={item?.background}
                    description={"Upload a landscape sized photo from your device that represents the hotspots image. Don't worry, we've got the web-friendly optimization covered."}
                    onLoad={(event) => {
                    if (event.target.files[0]) {
                        
                        setInitialState(false);
                        setSelectedComponent(index);
                        uploadInductionImage({
                            image: event.target.files[0],
                        });
                        
                    }
                    }}
                />

               
               <br />
               <h4 className={classes.formHeading}>Hotspots</h4>

               {/* Hotspots */}
               {item?.hotspots.map((hotspotitem,hindex) =>
                    <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.name_id ? translations[selectedLanguage.code][hotspotitem?.name_id] ? translations[selectedLanguage.code][hotspotitem?.name_id] : '' : ''}
                        name={hotspotitem?.name_id ? hotspotitem?.name_id : "component_hotspot_name_"+hindex}
                        characterLimit={250}
                        onChange={(e) => {
                            /*
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],name:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            */
                            
                            //update translation
                            updateTranslation(selectedLanguage.code, hotspotitem?.name_id, e.target.value);
                        }}
                        label={"Hotspot Name "+ (hindex + 1)}
                        placeholder={"Hotspot Name"}
                        error={""}
                        tooltipText={
                        "Hotspot name that is displayed when the hotspot icon is clicked."
                        }
                    />
                   <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.content_id ? translations[selectedLanguage.code][hotspotitem?.content_id] ? translations[selectedLanguage.code][hotspotitem?.content_id] : '' : ''}
                        name={hotspotitem?.content_id ? hotspotitem?.content_id : "component_hotspot_content_"+hindex}
                        type="textarea"
                        characterLimit={250}
                        onChange={(e) => {
                            /*
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],content:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            */
                            
                            //update translation
                            updateTranslation(selectedLanguage.code, hotspotitem?.content_id, e.target.value);
                        }}
                        label={"Hotspot Content "+ (hindex + 1)}
                        placeholder={"Hotspot Content"}
                        error={""}
                        tooltipText={
                        "Hotspot content that is displayed when the hotspot icon is clicked."
                        }
                   />

                   <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                   <div style={{width: '200px'}}>
                   <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.coordinate.x}
                        name={ "component_hotspot_coordinate_x_"+hindex}
                        type={'number'}                       
                        onChange={(e) => {
                            
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],coordinate:{x: e.target.value, y: newHotspotUpdate[j].coordinate.y}};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            
                            
                        }}
                        label={"Pos X"}
                        placeholder={"Hotspot Position X"}
                        error={""}
                        tooltipText={
                        "Hotspot position X in pixels"
                        }
                   />
                   </div> 
                   <div style={{width: '200px'}}>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={hotspotitem?.coordinate.y}
                        name={ "component_hotspot_coordinate_y_"+hindex}
                        type={'number'}                       
                        onChange={(e) => {
                            
                            //create new array of hotspots and update the selected one
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],coordinate:{y: e.target.value, x: newHotspotUpdate[j].coordinate.x}};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            
                            
                        }}
                        label={"Pos Y"}
                        placeholder={"Hotspot Position Y"}
                        error={""}
                        tooltipText={
                        "Hotspot position Y in pixels"
                        }
                   />
                   </div>
                   </div>
                
                   <br />
                   </>
               )}

                <button
                    id={"new_hotspot"}
                    type="submit"
                    style={{width: '150px'}}
                    onClick={function(){
                        //create new array of hotspots and update the selected one
                        var newHotspotUpdate = [ ...item?.hotspots ]
                        
                        //add a new item
                        const newId = newHotspotUpdate.length + 1;
                        newHotspotUpdate.push({
                            coordinate: {x: 0, y: 0},
                            name: "Hotspot "+newId,
                            name_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.hotspot.' + newId + '.name',
                            content: "Lorem ipsum dolor sit amet. Quo internos sequi rem eius molestias est atque Quis sit officiis inventore mollitia aliquid aut odit voluptatem id nihil molestiae. Ea consectetur mollitia aut delectus aperiam ex sint sapiente hic voluptatem mollitia ea natus consequatur et minima odio qui eius tempore.",
                            content_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.hotspot.' + newId + '.content',
                            type: "text"
                        });

                        //now put it back to components
                        var newComponentUpdate = [ ...section.data.components ]
                    
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    New Hotspot
                </button>               

             
              </div>
            )}

            {item?.type == 'carousel' && (
              <div>
              <br />
              <h4 className={classes.formHeading}>Carousel Slides</h4>

               {/* Carousel Content */}
               {item?.content.map((carouselItem,cIndex) =>
                <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={carouselItem?.title_id ? translations[selectedLanguage.code][carouselItem?.title_id] ? translations[selectedLanguage.code][carouselItem?.title_id] : '' : ''}
                        name={carouselItem?.title_id ? carouselItem?.title_id : "component_carousel_name_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            
                            //create new array of hotspots and update the selected one
                            /*
                            var newHotspotUpdate = [ ...item?.hotspots ]
                            
                            for(var j = 0; j < newHotspotUpdate.length; j++)
                            {
                                if(j == hindex)
                                    newHotspotUpdate[j] = {...newHotspotUpdate[j],name:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],hotspots:newHotspotUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            */

                            //update translation
                            updateTranslation(selectedLanguage.code, carouselItem?.title_id, e.target.value);
                        }}
                        label={"Carousel Title "+ (cIndex + 1)}                        
                        placeholder={"Carousel Title"}
                        error={""}
                        tooltipText={
                        "Title for the carousel slide " + (cIndex + 1)
                        }
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={carouselItem?.content_id ? translations[selectedLanguage.code][carouselItem?.content_id] ? translations[selectedLanguage.code][carouselItem?.content_id] : '' : ''}
                        name={carouselItem?.content_id ? carouselItem?.content_id : "component_carousel_content_"+cIndex}
                        characterLimit={250}
                        type="textarea"
                        onChange={(e) => {

                            //update translation
                            updateTranslation(selectedLanguage.code, carouselItem?.content_id, e.target.value);
                        }}
                        label={"Carousel Content "+ (cIndex + 1)}
                        placeholder={"Carousel Content"}
                        error={""}
                        tooltipText={
                        "Content for the carousel slide " + (cIndex + 1) + " Supports HTML."
                        }
                    />

                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={carouselItem?.image}
                        description={"Upload a photo for the carousel. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            setSelectedKey('image');
                            setSelectedCard(cIndex);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />

                    <br />
                    </>
               )}

                <button
                    id={"new_carousel"}
                    type="submit"
                    style={{width: '150px'}}
                    onClick={function(){
                        //create new array of carousel and update the selected one
                        var newCarouselUpdate = [ ...item?.content ];
                        
                        //add a new item
                        const newId = newCarouselUpdate.length + 1;
                        newCarouselUpdate.push({
                            title: "New Slide "+ newId,
                            title_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.carousel.' + newId + '.title',
                            content: "Lorem ipsum dolor sit amet. Est libero voluptatem sed earum culpa et magni quia aut corrupti sunt in omnis nisi in iusto voluptas.",
                            content_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.carousel.' + newId + '.content',
                            
                        });

                        //now put it back to components
                        var newComponentUpdate = [ ...section.data.components ]
                    
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],content:newCarouselUpdate};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    New Slide
                </button> 
              
              </div>
            )}



            {item?.type == 'flipcard' && (
              <div>
                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '' : ''}
                    name={"component_"+index+"_content"}
                    characterLimit={256}
                    type="textarea"
                    onChange={(e) => {
                         
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.content_id,e.target.value);
                    }}
                    label={"Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content for the component. Supports HTML"
                    }
              />
              <br />
              <h4 className={classes.formHeading}>Flipcards</h4>

               {/* Flipcard Content */}
               {item?.cards.map((flipcardItem,cIndex) =>
                <>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.title_id ? translations[selectedLanguage.code][flipcardItem?.title_id] ? translations[selectedLanguage.code][flipcardItem?.title_id] : '' : ''}
                        name={flipcardItem?.title_id ? flipcardItem?.title_id : "component_flipcard_title_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.title_id, e.target.value);
                        }}
                        label={"Card "+ (cIndex + 1) + " Title"}                        
                        placeholder={"Card Title"}
                        error={""}
                        tooltipText={
                        "Title for the card nr " + (cIndex + 1)
                        }
                    />

                    <h5 className={classes.formSubHeading}>Front</h5>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.front_title_id ? translations[selectedLanguage.code][flipcardItem?.front_title_id] ? translations[selectedLanguage.code][flipcardItem?.front_title_id] : '' : ''}
                        name={flipcardItem?.front_title_id ? flipcardItem?.front_title_id : "component_flipcard_front_title_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.front_title_id, e.target.value);
                        }}
                        label={"Title "}                        
                        placeholder={"Title"}
                        error={""}
                        tooltipText={
                        "Title for the flipcard for the front side " + (cIndex + 1)
                        }
                    />
                    
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.front_id ? translations[selectedLanguage.code][flipcardItem?.front_id] ? translations[selectedLanguage.code][flipcardItem?.front_id] : '' : ''}
                        name={flipcardItem?.front_id ? flipcardItem?.front_id : "component_flipcard_front_content_"+cIndex}
                        characterLimit={250}
                        type="textarea"
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.front_id, e.target.value);
                        }}
                        label={"Content "}                        
                        placeholder={"Content"}
                        error={""}
                        tooltipText={
                        "Content for the front side " + (cIndex + 1)
                        }
                    />
                    
                    {/*
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.front_image ? flipcardItem?.front_image  : ' '}
                        name={"component_flipcard_front_image_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //create new array of flipcards and update the selected one
                            
                            var newCardsUpdate = [ ...item?.cards ]
                            
                            for(var j = 0; j < newCardsUpdate.length; j++)
                            {
                                if(j == cIndex)
                                    newCardsUpdate[j] = {...newCardsUpdate[j],front_image:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],cards:newCardsUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                            
                        }}
                        label={"Image URL "}                        
                        placeholder={"https://..."}
                        error={""}
                        tooltipText={
                        "Image url for the front of the card."
                        }
                    />
                    */}

                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={flipcardItem?.front_image}
                        description={"Upload a square photo for the front side of the card. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            setSelectedKey('front_image');
                            setSelectedCard(cIndex);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />


                    <h5 className={classes.formSubHeading}>Back</h5>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.back_title_id ? translations[selectedLanguage.code][flipcardItem?.back_title_id] ? translations[selectedLanguage.code][flipcardItem?.back_title_id] : '' : ''}
                        name={flipcardItem?.back_title_id ? flipcardItem?.back_title_id : "component_flipcard_back_title_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.back_title_id, e.target.value);
                        }}
                        label={"Title"}                        
                        placeholder={"Title"}
                        error={""}
                        tooltipText={
                        "Title for the flipcard for the back " + (cIndex + 1)
                        }
                    />
                    
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.back_id ? translations[selectedLanguage.code][flipcardItem?.back_id] ? translations[selectedLanguage.code][flipcardItem?.back_id] : '' : ''}
                        name={flipcardItem?.back_id ? flipcardItem?.back_id : "component_flipcard_back_content_"+cIndex}
                        characterLimit={250}
                        type="textarea"
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, flipcardItem?.back_id, e.target.value);
                        }}
                        label={"Content"}                        
                        placeholder={"Content"}
                        error={""}
                        tooltipText={
                        "Content for the back, card nr: " + (cIndex + 1)
                        }
                    />
                    
                    {/*
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={flipcardItem?.back_image ? flipcardItem?.back_image  : ' '}
                        name={"component_flipcard_back_image_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //create new array of flipcards and update the selected one
                            
                            var newCardsUpdate = [ ...item?.cards ]
                            
                            for(var j = 0; j < newCardsUpdate.length; j++)
                            {
                                if(j == cIndex)
                                    newCardsUpdate[j] = {...newCardsUpdate[j],back_image:e.target.value};

                            }

                            //now put it back to components
                            var newComponentUpdate = [ ...section.data.components ]
                        
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                    newComponentUpdate[j] = {...newComponentUpdate[j],cards:newCardsUpdate};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                            setSelectedSection(updatedData);
                        }}
                        label={"Image URL "}                        
                        placeholder={"https://..."}
                        error={""}
                        tooltipText={
                        "Image url for the back of the card."
                        }
                    />
                    */}
                    
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={flipcardItem?.back_image}
                        description={"Upload a square photo for the back side of the card. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            setSelectedKey('back_image');
                            setSelectedCard(cIndex);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />


                    <br />
                    </>
               )}
              
              </div>
            )}


            {item?.type == 'card_sort' && (
              <div>
                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.content_id ? translations[selectedLanguage.code][item?.content_id] ? translations[selectedLanguage.code][item?.content_id] : '' : ''}
                    name={"component_"+index+"_content"}
                    characterLimit={256}
                    type="textarea"
                    onChange={(e) => {
                            
                            /*
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                           */
                           

                           //update translation
                           updateTranslation(selectedLanguage.code,item?.content_id,e.target.value);
                    }}
                    label={"Content"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Content for the component. Supports HTML"
                    }
              />

              <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.success_modal_title_id ? translations[selectedLanguage.code][item?.success_modal_title_id] ? translations[selectedLanguage.code][item?.success_modal_title_id] : '' : ''}
                    name={"component_"+index+"_success_modal_title"}
                    characterLimit={256}
                    onChange={(e) => {
                            
                           //update translation
                           updateTranslation(selectedLanguage.code,item?.success_modal_title_id,e.target.value);
                    }}
                    label={"Success Modal Title"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Success Modal Title appears after all the cards are dropped to correct areas."
                    }
              />

               <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.success_modal_description_id ? translations[selectedLanguage.code][item?.success_modal_description_id] ? translations[selectedLanguage.code][item?.success_modal_description_id] : '' : ''}
                    name={"component_"+index+"_success_modal_description"}
                    characterLimit={256}
                    onChange={(e) => {
                            
                           //update translation
                           updateTranslation(selectedLanguage.code,item?.success_modal_description_id,e.target.value);
                    }}
                    label={"Success Modal Description"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Success Modal appears after all the cards are dropped to correct areas. This is the content field of that modal."
                    }
                />

                <Input
                    hasTooltip={true}
                    order="column"
                    value={item?.category_title_id ? translations[selectedLanguage.code][item?.category_title_id] ? translations[selectedLanguage.code][item?.category_title_id] : '' : ''}
                    name={"component_"+index+"_category_title"}
                    characterLimit={256}
                    onChange={(e) => {
                            
                           //update translation
                           updateTranslation(selectedLanguage.code,item?.category_title_id,e.target.value);
                    }}
                    label={"Category Title"}
                    placeholder={"Content here"}
                    error={""}
                    tooltipText={
                    "Text on top of the options"
                    }
                />


            
              <br />
              <h4 className={classes.formHeading}>Cards</h4>

              {item?.cards.map((cardItem,cIndex) =>
                <>
                    <h5 className={classes.formSubHeading}>Card {(cIndex + 1)}</h5>
                    <Input
                        hasTooltip={true}
                        order="column"
                        value={cardItem?.name_id ? translations[selectedLanguage.code][cardItem?.name_id] ? translations[selectedLanguage.code][cardItem?.name_id] : '' : ''}
                        name={cardItem?.name_id ? cardItem?.name_id : "component_cardsort_name_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, cardItem?.name_id, e.target.value);
                        }}
                        label={"Name"}                        
                        placeholder={"Card Name"}
                        error={""}
                        tooltipText={
                        "Name for the card nr " + (cIndex + 1)
                        }
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={cardItem?.content_id ? translations[selectedLanguage.code][cardItem?.content_id] ? translations[selectedLanguage.code][cardItem?.content_id] : '' : ''}
                        name={cardItem?.content_id ? cardItem?.content_id : "component_cardsort_content_"+cIndex}
                        characterLimit={250}
                        onChange={(e) => {
                            //update translation
                            updateTranslation(selectedLanguage.code, cardItem?.content_id, e.target.value);
                        }}
                        label={"Content"}                        
                        placeholder={"Card Content"}
                        error={""}
                        tooltipText={
                        "Content for the card nr " + (cIndex + 1)
                        }
                    />
                    <br />
                    <p>Option Cards</p>
                    {cardItem?.categories.map((categoryItem,catIndex) =>
                        <Input
                            hasTooltip={true}
                            order="column"
                            value={categoryItem?.name_id ? translations[selectedLanguage.code][categoryItem?.name_id] ? translations[selectedLanguage.code][categoryItem?.name_id] : '' : ''}
                            name={categoryItem?.name_id ? categoryItem?.name_id : "component_cardsort_category_"+categoryItem?.id}
                            characterLimit={250}
                            onChange={(e) => {
                                //update translation
                                updateTranslation(selectedLanguage.code, categoryItem?.name_id, e.target.value);
                            }}
                            label={"Option "+ (catIndex + 1) + " Name"}                        
                            placeholder={"Option Name"}
                            error={""}
                            tooltipText={
                            "Name for the option nr " + (catIndex + 1)
                            }
                        />
                    )}

                    <p>Correct Option</p>
                    
                    <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Correct Option"}
                        onChange={(val) => {
                          
                            //copy the components
                            var newComponentUpdate = [ ...section.data.components ];
                            
                            //copy the cards in the selected component
                            var cardsUpdate = [ ...newComponentUpdate[index].cards];

                            //update the card with new values
                            cardsUpdate[cIndex] = {...cardsUpdate[cIndex], 
                                                   answer: val?.value
                                                  };
                            
                            //put the cards back to components
                            newComponentUpdate[index] = {...newComponentUpdate[index],cards: cardsUpdate};

                            //put the component back to section
                            var updatedSectionData = {...section,data:{...section.data, components: newComponentUpdate}}
                            
                            //console.log('updatedSection',updatedSectionData);

                            //update the data
                            setSelectedSection(updatedSectionData);
                            
                            //to make sure we have a content changed alert if user tries to move away
                            setEditData(true);
                            
                          
                        }}
                        tabSelectsValue={selectedLanguage}
                        value={cardItem?.categories.filter(
                            (option) => option.id === cardItem?.answer
                          ).map(function(obj) {
                            return {value: obj.id, label: obj.name_id ? translations[selectedLanguage.code][obj?.name_id] ? translations[selectedLanguage.code][obj?.name_id] : '-'  : obj?.name};
                          })}
                        options={cardItem?.categories.map(function(obj) {
                            return {value: obj.id, label: obj.name_id ? translations[selectedLanguage.code][obj?.name_id] ? translations[selectedLanguage.code][obj?.name_id] : '-'  : obj?.name};
                          })}
                        isSearchable={false}
                    />


                    <br />
                </>
                
              )}

                <button
                    id={"new_card"}
                    type="submit"
                    style={{width: '150px'}}
                    onClick={function(){
                        //create new array of hotspots and update the selected one
                        var newCardUpdate = [ ...item?.cards ];
                        
                        //add a new item
                        const newId = newCardUpdate.length + 1;
                        newCardUpdate.push({
                            id: newId,
                            answer: 2,
                            name: "Card content " + newId,
                            name_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.cardsort.' + newId + '.name',
                            content: " ",
                            content_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.cardsort.' + newId + '.content',
                            feedback: "",
                            feedback_id: "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.cardsort.' + newId + '.feedback',
                            column: "START",
                            image: "",
                            icon: "",
                            categories: [
                                            {
                                                "id": 2,
                                                "name": "Category 2",
                                                "name_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.cardsort.' + newId + '.category.2.name',
                                            },
                                            {
                                                "id": 1,
                                                "name": "Category 1",
                                                "name_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.'+index+'.cardsort.' + newId + '.category.1.name',
                                            }
                            ]
                        });

                        //now put it back to components
                        var newComponentUpdate = [ ...section.data.components ]
                    
                        for(var j = 0; j < newComponentUpdate.length; j++)
                        {
                            if(j == index)
                                newComponentUpdate[j] = {...newComponentUpdate[j],cards:newCardUpdate};

                        }
                        
                        var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                        setSelectedSection(updatedData);
                        
                    }}
                    disabled={false}
                    className={classes.darkButton}
                    >
                    New Card
                </button>

              </div>

            )}
            
            <br />

            </>
        )}

        <hr />       
        <h4>New Block</h4>

        <Select
            styles={{
                control: () => ({
                width: "100%",
                height: 44,
                display: "flex",
                background: "#f8f8f8",
                paddingLeft: "10px",
                color: "#40507e",
                border: "1px solid #E0E0E0",
                boxSizing: "border-box",
                borderRadius: "6px",
                boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: "bold",
                }),
            }}
            placeholder={"Block type"}
            onChange={(val) => {
                setSelectedBlockType(val);
            }}
            tabSelectsValue={selectedLanguage}
            value={selectedBlockType}
            options={
                [{value: 'image_text_full', 'label': "Fullpage Image & Text"},
                 {value: 'image_text_regular', 'label': "Image & Text Block"}, 
                 {value: 'flipcard', 'label': "Flip Cards"},
                 {value: 'hotspots', 'label': "Hotspots"},
                 {value: 'card_sort', 'label': "Card Sort"},
                 {value: 'carousel', 'label': "Carousel"}
                ]
            }
            isSearchable={false}
        />

        <br />
        <button
            id={"add_a_block"}
            type="submit"
            style={{width: '100px'}}
            onClick={function(){  
                //add a new, empty component

                var newComponent = {
                    "type": selectedBlockType?.value,
                };

                const newId = section.data.components.length + 1;

                if(selectedBlockType.value == 'image_text_full')
                {
                    newComponent = {
                        ...newComponent,
                        "header": "Fullscreen Image Block",
                        "header_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.header',
                        "title": "Fullscreen Image Block",
                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.title',
                        "image": "https://keepsafe.work/images/04.02_Block_image_text_full.jpg",
                        "content": "A picture paints a thousand words",
                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.content',
                        "interactive": false
                    }
                }
                else if(selectedBlockType.value == 'image_text_regular')
                {
                    newComponent = {
                        ...newComponent,
                        "header": "Image & Text Block",
                        "header_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.header',
                        "title": "When a little more text is required...",
                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.title',
                        "image": "https://keepsafe.work/images/04.02_Block_image_text_full.jpg",
                        "content": "A picture paints a thousand words",
                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.content',
                        "interactive": false
                    }
                }
                else if(selectedBlockType.value == 'flipcard')
                {
                    newComponent = {
                        ...newComponent,
                        "show_title_bar": true,
                        "cards": [
                                    {
                                        "title": "Do",
                                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.flipcard.1.title',
                                        "front_title": "Title front",
                                        "front_title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.flipcard.1.front_title',
                                        "front": "Front content",
                                        "front_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.flipcard.1.front',
                                        "front_image": "",
                                        "back_title": "Title back",
                                        "back_title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.flipcard.1.back_title',
                                        "back": "Back Content",
                                        "back_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.flipcard.1.back',
                                        "back_image": ""
                                    }
                        ],
                        "title": "Flip Card",
                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.title',
                        "content": "A great way to get learners 'hands-on'. Cards can contain both imagery and text.",
                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.content',
                        "items_per_row": 1,
                        "interactive": false
                    }
                }
                else if(selectedBlockType.value == 'hotspots')
                {
                    newComponent = {
                        ...newComponent,
                        "background": "https://keepsafe.work/images/hotspot_example.jpg",
                        "title": "Common Risks",
                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.title',
                        "content": "Click on each hotspot to <b>learn more</b>",
                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.content',
                        "button_label_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.button_label',
                        "conclusion_title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.conclusion_title',
                        "conclusion_content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.conclusion_content',
                        "hotspots": [
                            {
                                "coordinate": {
                                    "x": 73,
                                    "y": 145
                                },
                                "name": "Hotspot 1",
                                "name_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.hotspot.name.1',
                                "content": "<p>Lorem Ipsum Dolor<p><p><b>Quo</b> internos sequi rem eius molestias est atque Quis sit officiis inventore mollitia aliquid aut odit voluptatem id nihil molestiae</p>",
                                "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.hotspot.content.1',
                                "image": "https://keepsafe.work/images/rule_icon_6.png",
                                "type": "icon"
                            }
                            
                        ],
                        "interactive": true
                    }
                }
                else if(selectedBlockType.value == 'card_sort')
                {
                    newComponent = {
                        ...newComponent,
                        "title": "When a little more text is required...",
                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.title',
                        "content": "A picture paints a thousand words",
                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.content',
                        "interactive": true,
                        "success_modal_title": "You've completed the risks section.",
                        "success_modal_title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.success_modal_title',
                        "success_modal_description": "Let’s continue.",
                        "success_modal_description_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.success_modal_description',
                        "category_title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.category_title',
                        "cards": [
                                    {
                                        "id": 1,
                                        "answer": 2,
                                        "name": "Card content 1",
                                        "name_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.card.1.name',
                                        "content": " ",
                                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.card.1.content',
                                        "feedback": "",
                                        "feedback_id": "-",
                                        "column": "START",
                                        "image": "",
                                        "icon": "",
                                        "categories": [
                                            {
                                                "id": 2,
                                                "name": "Category 2",
                                                "name_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.card.1.category.2',
                                            },
                                            {
                                                "id": 1,
                                                "name": "Category 1",
                                                "name_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.card.1.category.1',
                                            }
                                        ]
                                    }
                                ]
                    }
                }
                else if(selectedBlockType.value == 'carousel')
                {
                    newComponent = {
                        ...newComponent,
                        "title": "When a little more text is required...",
                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.title',
                        "content": [
                                    {
                                        "title": "1 of 2",
                                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.carousel.1.title',
                                        "content": "Lorem ipsum dolor sit amet. Est libero voluptatem sed earum culpa et magni quia aut corrupti sunt in omnis nisi in iusto voluptas.",
                                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.carousel.1.content',
                                    },
                                    {
                                        "title": "2 of 2",
                                        "title_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.carousel.2.title',
                                        "content": "Eos nostrum nulla et voluptatem enim et amet similique sed consequatur tempora.",
                                        "content_id": "induction."+induction_id+".page." + page?.id + ".rule." + chapter?.id + '.component.' + newId + '.carousel.2.content',
                                        "image": "https://keepsafe.work/images/group-construction-engineer-team-standard.jpg"
                                    },
                                    ],
                        "interactive": true
                    }
                }
                
                console.log('newComponent',newComponent);

                var newComponentUpdate = [ ...section.data.components ];
                newComponentUpdate.push(newComponent);

                var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                setSelectedSection(updatedData);


            }}
            disabled={false}
            className={classes.darkButton}
            >
            Add a block
        </button>


  </div>;
};


const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(ChaptersForm);