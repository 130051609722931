import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectLanguage } from "store/language/actions";
import { organisationRequest } from "store/auth/actions";

import {
  makeSelectSelectedLanguage,
  makeSelectLanguages,
} from "store/language/selector";

import {
  makeSelectOrganisation
} from "store/auth/selector";

//styles
import classes from "./languageSelection.module.scss";

//components
import { FormattedMessage } from "react-intl";
import Footer from "components/UI/footer";
import Button from "components/UI/button";

//utils
import { isNil } from "lodash";
import queryString from "query-string";

import RedTick from "./red-tick.svg";
import cn from "classnames";

const LanguageSelection = ({
  languages,
  history,
  selectedLanguage,
  setSelectLanguage,
  organisation,
  getOrganization
}) => {
  const [selectedSite, setSite] = useState();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [redirectRoute, setRedirectRoute] = useState(null);
  const [siteManager, setSiteManager] = useState(null);
  const [visible, setVisible] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  const [languageOptionsFirstCol, setLanguageOptionsFirstCol] = useState([]);
  const [languageOptionsSecondCol, setLanguageOptionsSecondCol] = useState([]);

  const languageList = {
    eng: "English",
    fil: "Filipino",
    chi: "中国人",
    mao: "Te Reo Māori",
    tr: "Türkçe",
  };

  const flagList = {
    en: "flag_en.png",
    fil: "flag_fil.png",
    chi: "flag_chi.png",
    mao: "flag_mao.png",
    tr: "flag_tr.png",
    nor: "flag_nor.png",
  };

  useEffect(() => {
    /*
    if (languages?.length) {
      const dfList = languages.map((item) => {
        const images = require.context("../../assets/flags", true);
        return {
          value: item.code,
          label: !isNil(languageList[item?.code])
            ? `${item.name} / ${languageList[item.code]}`
            : item.name,
          image: !isNil(flagList[item.code])
            ? images(`./${flagList[item.code]}`)
            : null,
        };
      });

      setLanguageOptions(dfList);

      //now divide into 2
      const middleIndex = Math.ceil(dfList.length / 2);
      const firstHalf = dfList.slice(0, middleIndex);
      const secondHalf = dfList.slice(middleIndex, dfList.length);

      setLanguageOptionsFirstCol(firstHalf);
      setLanguageOptionsSecondCol(secondHalf);

    }
    */
  }, [languages]);

  useEffect(() => {
    if (selectedLanguage) {
      setSelectedOption(selectedLanguage?.code);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const { site, redirect, sitemanager } = parsed;
    setSite(site);
    setRedirectRoute(redirect);
    setSiteManager(sitemanager);

    //get organisation details
    getOrganization();

    setTimeout(() => {
      setVisible(true);
    }, 500);
  }, []);

  useEffect(() => {
    if(organisation)
    {
        var dfList = [];

        if (languages?.length) {
          dfList = languages.map((item) => {
            const images = require.context("../../assets/flags", true);
            return {
              value: item.code,
              label: !isNil(languageList[item?.code])
                ? `${item.name} / ${languageList[item.code]}`
                : item.name,
              image: !isNil(flagList[item.code])
                ? images(`./${flagList[item.code]}`)
                : null,
            };
          });
          
        }

        if(organisation?.data['supported_languages'] && dfList.length > 0)
        {
            console.log('languageOptions',dfList);

            const newLanguageOptions = dfList.filter( (item) => organisation?.data['supported_languages'].includes(item.value) )

            console.log('languageOptions entered 2',newLanguageOptions);

            setLanguageOptions(newLanguageOptions);

            //now divide into 2
            const middleIndex = Math.ceil(newLanguageOptions.length / 2);
            const firstHalf = newLanguageOptions.slice(0, middleIndex);
            const secondHalf = newLanguageOptions.slice(middleIndex, newLanguageOptions.length);
      
            setLanguageOptionsFirstCol(firstHalf);
            setLanguageOptionsSecondCol(secondHalf);
            setUpdateCounter(1);
        }
        else
        {
          setLanguageOptions(dfList);
    
          //now divide into 2
          const middleIndex = Math.ceil(dfList.length / 2);
          const firstHalf = dfList.slice(0, middleIndex);
          const secondHalf = dfList.slice(middleIndex, dfList.length);
    
          setLanguageOptionsFirstCol(firstHalf);
          setLanguageOptionsSecondCol(secondHalf);
        }
    }
    else
    {
      if (languages?.length) {
        const dfList = languages.map((item) => {
          const images = require.context("../../assets/flags", true);
          return {
            value: item.code,
            label: !isNil(languageList[item?.code])
              ? `${item.name} / ${languageList[item.code]}`
              : item.name,
            image: !isNil(flagList[item.code])
              ? images(`./${flagList[item.code]}`)
              : null,
          };
        });
  
        setLanguageOptions(dfList);
  
        //now divide into 2
        const middleIndex = Math.ceil(dfList.length / 2);
        const firstHalf = dfList.slice(0, middleIndex);
        const secondHalf = dfList.slice(middleIndex, dfList.length);
  
        setLanguageOptionsFirstCol(firstHalf);
        setLanguageOptionsSecondCol(secondHalf);
        
      }
    }
  }, [organisation,languages]);



  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>
          <FormattedMessage
            defaultMessage={"Select desired language"}
            id={"language.selection"}
          />
        </h1>
        <div className={classes.FormWrapper}>
          <div className={visible ? classes.FirstColVisible : classes.FirstCol}>
            {updateCounter > 0 && languageOptionsFirstCol?.map((item, index) => (
              <div key={index} className={classes.personaItem}>
                <div
                  className={cn(
                    item?.value === selectedOption &&
                      classes.textWrapperSelected,
                    selectedOption !== item?.value
                      ? classes.textWrapperDisabled
                      : classes?.textWrapper
                  )}
                >
                  <img
                    alt={"flag"}
                    src={item?.image}
                    className={classes.languageImage}
                    onClick={() => {
                      setSelectedOption(item.value);
                      setSelectLanguage({
                        code: item.value,
                        name: item.label,
                      });
                    }}
                  />
                  <span className={classes.personaTitle}>{item.label}</span>
                </div>
              </div>
            ))}
          </div>

          <div
            className={cn(
              visible ? classes.SecondColVisible : classes.SecondCol
            )}
          >
            {languageOptionsSecondCol?.map((item, index) => (
              <div key={index} className={classes.personaItem}>
                <div
                  className={cn(
                    item?.value === selectedOption &&
                      classes.textWrapperSelected,
                    selectedOption !== item?.value
                      ? classes.textWrapperDisabled
                      : classes?.textWrapper
                  )}
                >
                  <img
                    alt={"flag"}
                    src={item?.image}
                    className={classes.languageImage}
                    onClick={() => {
                      setSelectedOption(item.value);
                      setSelectLanguage({
                        code: item.value,
                        name: item.label,
                      });
                    }}
                  />
                  <span className={classes.personaTitle}>{item.label}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer transparent isHidden={selectedOption != null ? false : true}>
        <Button
          formatID={"continue.button"}
          id="apply-button"
          text="Continue"
          type="submit"
          theme="blue"
          disabled={isNil(selectedOption)}
          onClick={() => {
            if (isNil(siteManager) && isNil(selectedSite)) {
              history.push(`/login`);
            } else if (isNil(siteManager)) {
              history.push(
                `/persona-selection?site=${selectedSite}&redirect=${redirectRoute}`
              );
            } else {
              history.push(
                `/persona-selection?site=${selectedSite}&sitemanager=${siteManager}&redirect=${redirectRoute}`
              );
            }
          }}
        />
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  selectedLanguage: makeSelectSelectedLanguage(),
  organisation: makeSelectOrganisation(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectLanguage: (val) => dispatch(selectLanguage(val)),
    getOrganization: (val) => dispatch(organisationRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);
