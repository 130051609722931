import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//action start
import { loginRequest, checkInRequest, organisationRequest } from "../../store/auth/actions";
import { getCompaniesRequest } from "../../store/supervisor/actions";

//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectOrganisation
} from "store/auth/selector";

import {
  makeSelectSelectedLanguage,
  makeSelectLanguages,
} from "store/language/selector";
import { makeSelectCompanies } from "store/supervisor/selector";
//selectors end

import { selectLanguage } from "store/language/actions";

//components start
import PrivacyPolicy from "../PrivacyPolicy";
import { Formik, Field, Form } from "formik";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Header from "components/UI/header";
import Input from "components/UI/input";
// import Select from "react-select";
// import AutoCompleteInput from "components/UI/AutoCompleteInput";
// import PhoneInput from "components/UI/phoneInput";
import Heading from "components/UI/heading";

//components end

import classes from "./auth.module.scss";
import LoginSchema from "./validationSchema";
import queryString from "query-string";
import BackArrow from "./back.svg";
import { FormattedMessage } from "react-intl";
import { isNil } from "lodash";
import cn from "classnames";

const Auth = (props) => {
  const [site, setSite] = useState(undefined);
  const [siteManager, setSiteManager] = useState(undefined);
  const [languageOptions, setLanguageOptions] = useState(false);
  const [userType, setUserType] = useState(false);
  const [selectedOption, setSelectedOption] = useState("en");
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [isPrivacyPolicyAgreed, setPrivacyPolicyAgreed] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState(null);

  useEffect(() => {
    if (props?.languages?.length) {
      const dfList = props.languages.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });

      setLanguageOptions(dfList);
    }
  }, [props.languages]);

  useEffect(() => {
    if (props?.selectedLanguage) {
      setSelectedOption(props?.selectedLanguage?.code);
    }
  }, [props.selectedLanguage]);

  const initialValues = {
    email: "",
    lastName: "",
    firstName: "",
    company: {
      id: null,
      value: "",
    },
    language: props?.selectedLanguage?.code,
    site: site,
    phoneNumber: "",
    siteManager: siteManager,
    userType: userType ? userType : "1",
    checkbox: false,
    redirect: redirectRoute,
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setSite({ site: parsed.site });
    setSiteManager(parsed.sitemanager);
    setUserType(parsed.userType);
    setRedirectRoute(parsed.redirect);
    props.getOrganization();
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        <Header transparent logo={props?.organisation?.data['rectangle_logo']} />
        {!isPrivacyPolicyOpen && (
          <Heading
            title={"Welcome to your online HSE induction"}
            titleId={"auth.heading.title"}
            description={"Write down your full name and company email address"}
            descriptionId={"auth.heading.description"}
          />
        )}
        {!isNil(userType) && (
          <div
            className={classes.backArrowWrapper}
            onClick={() => {
              props.history.push(`/persona-selection?site=${site?.site}`);
            }}
          >
            <img
              alt={"Back Arrow"}
              className={classes.backArrow}
              src={BackArrow}
            />
          </div>
        )}
        <Formik
          validationSchema={LoginSchema}
          validateOnChange={true}
          validateOnBlur={true}
          val
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            props.onSubmitForm(values);
          }}
        >
          {({ values, errors, isValid, setFieldValue, touched }) => {
            return (
              <Form>
                <div className={classes.FormWrapper}>
                  {/* {languageOptions && !isNil(site) && isNil(site?.site) && (
                    <div className={classes.Container}>
                      <label className={classes.BlockLabel}>
                        <FormattedMessage
                          id={"login.language"}
                          defaultMessage={"Language"}
                        />
                      </label>
                      <Select
                        styles={{
                          control: () => ({
                            width: "100%",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Select Languages"}
                        onChange={(val) => {
                          setSelectedOption(val.value);
                          props.setSelectLanguage({
                            code: val.value,
                            name: val.label,
                          });
                        }}
                        tabSelectsValue={selectedOption}
                        value={languageOptions.filter(
                          (option) => option.value === selectedOption
                        )}
                        options={languageOptions}
                        isSearchable={false}
                      />
                    </div>
                  )} */}
                  <Field
                    error={errors && errors.firstName}
                    placeholder={"login.firstName.placeholder"}
                    label={"login.firstName"}
                    name="firstName"
                    value={values.firstName}
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                    component={Input}
                  />
                  <Field
                    error={errors && errors.lastName && errors.lastName.length}
                    placeholder={"login.lastName.placeholder"}
                    label={"login.lastName"}
                    name="lastName"
                    value={values.lastName}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                    component={Input}
                  />
                  {/* <Field
                    error={
                      errors &&
                      errors?.company?.value &&
                      errors?.company?.value?.length
                    }
                    placeholder={"login.companyName.placeholder"}
                    label={"login.companyName"}
                    name="company"
                    value={values.company}
                    data={props?.companies?.results}
                    onChange={(e) => {
                      setFieldValue("company", e);
                      props.getCompanies({ search: e.value });
                    }}
                    component={AutoCompleteInput}
                  /> */}
                  <Field
                    error={errors && errors.email && errors.email.length}
                    placeholder={"login.email.placeholder"}
                    label={"login.email"}
                    name="email"
                    value={values.email}
                    onChange={(e) =>
                      setFieldValue("email", e.target.value.trim())
                    }
                    component={Input}
                  />
                  {/* <Field
                    error={errors && errors.phoneNumber && true}
                    placeholder={"login.phoneNumber.placeholder"}
                    label={"login.phoneNumber"}
                    defaultLabel={"Phone number"}
                    defaultPlaceholder={"Phone number"}
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={(e) => setFieldValue("phoneNumber", e)}
                    component={PhoneInput}
                  /> */}
                  <Field
                    name="checkbox"
                    // @ts-ignore
                    component={({ field }) => (
                      <div className={classes.termsWrapper}>
                        <label className={cn(classes.custom_checkbox)}>
                          {
                            <span>
                              <FormattedMessage
                                defaultMessage={
                                  "Contact information is collected to generate work-related notifications and messaging"
                                }
                                id={"auth.checkbox.info"}
                              />
                            </span>
                          }
                          <input
                            name={"checkbox"}
                            type="checkbox"
                            checked={isPrivacyPolicyAgreed}
                            onChange={(e) => {
                              setFieldValue("checkbox", e.target.checked);
                              setPrivacyPolicyAgreed(e.target.checked);
                            }}
                          />
                          <span className={classes.checkmark}></span>
                        </label>
                        <span
                          className={classes.readMore}
                          onClick={() => {
                            setPrivacyPolicyOpen(true);
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          <FormattedMessage
                            defaultMessage={"Read More"}
                            id={"auth.checkbox.cta"}
                          />
                        </span>
                      </div>
                    )}
                  />
                </div>
                {isPrivacyPolicyOpen && (
                  <PrivacyPolicy
                    setFieldValue={setFieldValue}
                    setPrivacyPolicyOpen={setPrivacyPolicyOpen}
                    isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                    setPrivacyPolicyAgreed={setPrivacyPolicyAgreed}
                  />
                )}

                <div
                  className={cn(
                    !isPrivacyPolicyOpen && isValid && isPrivacyPolicyAgreed
                      ? classes.FooterWrapper
                      : classes.FooterContainerHidden
                  )}
                >
                  <div className={classes.FooterContainer}>
                    <Button
                      disabled={!isPrivacyPolicyAgreed}
                      formatID="login.button"
                      theme="blue"
                      loading={props.loading}
                      id="apply-button"
                      text="Get Started"
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  selectedLanguage: makeSelectSelectedLanguage(),
  companies: makeSelectCompanies(),
  organisation: makeSelectOrganisation(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(loginRequest(val)),
    checkIn: (val) => dispatch(checkInRequest(val)),
    setSelectLanguage: (val) => dispatch(selectLanguage(val)),
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getOrganization: (val) => dispatch(organisationRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
