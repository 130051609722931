import React, { useState } from "react";
import classes from "./modal.module.scss";
import ReactLoading from "react-loading";
import Input from "../input";

const Modal = ({
  open,
  cancel,
  action,
  loading
}) => {
  const [option, setOption] = useState(false);
  const [name, setName] = useState("");

  return (
    <>
      {open && (
        <>
          <div className={classes.modalContainer}>
            <div
              className={classes.modal}
              style={{
                width: "608px",
                height: "482px",
                alignItems: "center",
              }}
            >
              <>
                <div className={classes.headerContainer}>
                  <span>New Induction</span>
                </div>
                <div
                  style={{ width: "80%", margin: "0 auto" }}
                  className={classes.bodyContainer}
                >
                  
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label={"Induction name"}
                    placeholder={"Type new induction name"}
                    />
                  
                </div>
                <div className={classes.footerContainer}>
                  <div
                    className={classes.cancelButton}
                    onClick={() => cancel()}
                  >
                    <span>Cancel</span>
                  </div>
                  <div
                    onClick={() => {
                      
                        action({
                          name: name,
                          callBack: () => {
                            cancel();
                          },
                        });
                      
                    }}
                    className={classes.sendButton}
                  >
                    {!loading ? (
                      <span>Create</span>
                    ) : (
                      <ReactLoading
                        width={"20px"}
                        height={"20px"}
                        color={"#ffffff"}
                        type={"spin"}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className={classes.modalBackground} onClick={() => cancel()} />
        </>
      )}
    </>
  );
};

export default Modal;
