import React,{useState} from "react";
import classes from "./modal.module.scss";
import WarningIcon from "assets/supervisorIcons/warning.svg";
import ReactLoading from "react-loading";
import Select from "react-select";

const Modal = ({
  open,
  cancel,
  action,
  type,
  data,
  totalUsers,
  rolesList,
  loading,
  deleteTitle,
  deleteDescription,
  buttonTitle,
  updateData
}) => {

  const pageTypes = [
    {value: 'introduction',label: 'Introduction'},
    {value: 'welcome',label: 'Welcome - Video Block'},
    {value: 'post_login',label: 'Prequal'},
    {value: 'all-chapters-complete',label: 'All Chapters Complete'},
    {value: 'site-rules',label: 'Site Rules'},
    {value: 'complete',label: 'Complete'},
  ];

  const [pageType, setPageType] = useState();


  return (
    <>
      {open && (
        <>
          <div className={classes.modalContainer}>
            <div
              className={classes.modal}
              style={{
                width: type === "info" ? "466px" : "597px",
                height: type === "info" ? "482px" : "219px",
              }}
            >
              {type === "info" ? (
                <>
                  <div className={classes.headerContainer}>
                    <span>
                      You are about to send an invitation to the group of users
                    </span>
                  </div>
                  <div className={classes.bodyContainer}>
                    <div className={classes.wrapper}>
                      <span className={classes.title}>Users:</span>
                      <span className={classes.description}>{totalUsers}</span>
                    </div>
                    <div className={classes.wrapper}>
                      <span className={classes.title}>Roles:</span>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {rolesList.map((item) => (
                          <span className={classes.description}>
                            {
                              data.columns
                                .map((a) => a.values[3].value)
                                .filter((b) => b === item.name).length
                            }{" "}
                            {item.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={classes.footerContainer}>
                    <div
                      className={classes.cancelButton}
                      onClick={() => cancel()}
                    >
                      <span>Cancel</span>
                    </div>
                    <div
                      onClick={() => !loading && action()}
                      className={classes.sendButton}
                    >
                      {!loading ? (
                        <span>Send</span>
                      ) : (
                        <ReactLoading
                          width={"20px"}
                          height={"20px"}
                          color={"#ffffff"}
                          type={"spin"}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) :
              type === "editor" ? (
                <>
                  <div className={classes.headerContainer}>
                    <span>
                      Create a new page
                    </span>
                  </div>
                  <div className={classes.bodyContainer}>
   

                    <Select
                        styles={{
                          control: () => ({
                            width: "250px",
                            height: 44,
                            display: "flex",
                            background: "#f8f8f8",
                            paddingLeft: "10px",
                            color: "#40507e",
                            border: "1px solid #E0E0E0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }),
                        }}
                        placeholder={"Select Type"}
                        onChange={(val) => {
                          setPageType(val);
                          updateData('type',val.value);
                          
                        }}
                        tabSelectsValue={''}
                        value={pageType}
                        options={pageTypes}
                        isSearchable={false}
                      />  

                    
                  </div>
                  <div className={classes.footerContainer}>
                    <div
                      className={classes.cancelButton}
                      onClick={() => cancel()}
                    >
                      <span>Cancel</span>
                    </div>
                    <div
                      onClick={() => !loading && action()}
                      className={classes.sendButton}
                    >
                      {!loading ? (
                        <span>Create</span>
                      ) : (
                        <ReactLoading
                          width={"20px"}
                          height={"20px"}
                          color={"#ffffff"}
                          type={"spin"}
                        />
                      )}
                    </div>
                  </div>                    
                </>
              ) : (
                <>
                  <div className={classes.deleteHeader}>
                    <img
                      alt={"warning icon"}
                      src={WarningIcon}
                      style={{
                        width: "19px",
                        height: "16px",
                        marginLeft: "25px",
                        marginRight: "25px",
                        display: "flex",
                      }}
                    />
                    <span>{deleteTitle}</span>
                  </div>
                  <div className={classes.deleteBody}>
                    <span>
                      {deleteDescription
                        ? deleteDescription
                        : "Note that this action is irreversible"}
                    </span>
                  </div>
                  <div className={classes.deleteFooter}>
                    <div
                      onClick={() => cancel()}
                      className={classes.deleteCancelButton}
                    >
                      <span>Cancel</span>
                    </div>
                    <div
                      onClick={() => action()}
                      className={classes.deleteButton}
                    >
                      <span>{buttonTitle ? buttonTitle : "Delete"}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.modalBackground} onClick={() => cancel()} />
        </>
      )}
    </>
  );
};

export default Modal;
